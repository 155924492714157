import { ICellRendererParams } from "ag-grid-community";
import React, { useCallback, useEffect, useRef } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { OrderDetailDTO } from "../../../services/ApiClient";

interface INumberInputRendererProps {
  showAsPrice?: boolean;
  updateNumber: (currentOrder: OrderDetailDTO) => void;
  pickPackStatus: number;
}

export const NumberInputRenderer = (
  props: ICellRendererParams & INumberInputRendererProps
) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [focused] = React.useState(false);

  const onBlur = useCallback(
    (e: React.ChangeEvent<any>) => {
      const data = { ...props.data };
      const colId = props.column?.getColId()!;
      data[colId] = Number(e.target.value);
      props.updateNumber(data);
    },
    [props]
  );

  useEffect(() => {
    if (focused && inputRef.current) {
      inputRef.current.focus();
    }
  }, [focused]);

  const NumberField = () => {
    return (
      <Form.Control
        type="number"
        placeholder="0"
        defaultValue={props.value}
        min="0"
        max="10000"
        step="1"
        onBlur={onBlur}
        ref={inputRef}
      />
    );
  };

  const PriceField = () => {
    return (
      <Form.Control
        type="number"
        placeholder="0"
        defaultValue={props.value}
        min="0.00"
        max="10000.00"
        step="0.01"
        onBlur={onBlur}
        ref={inputRef}
      />
    );
  };

  return (
    <>
      {props.data.pickPackStatus === props.pickPackStatus ||
      props.data.deleted ? (
        <>{props.value}</>
      ) : (
        <div className="fd-input-container">
          {props.showAsPrice ? (
            <InputGroup className="fd-input-container">
              <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
              <PriceField />
            </InputGroup>
          ) : (
            <NumberField />
          )}
        </div>
      )}
    </>
  );
};
