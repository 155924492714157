import { Configuration, PopupRequest } from "@azure/msal-browser";
/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */

export const b2cPolicies = {
  names: {
    singIn: "B2c_1_Sign_In",
  },
  authorities: {
    signIn: {
      authority: process.env.REACT_APP_B2C_SIGNIN_AUTHORITY,
    },
  },
  authorityDomain: process.env.REACT_APP_B2C_AUTHORITY_DOMAIN,
};

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_B2C_CLIENT_ID!,
    authority: b2cPolicies.authorities.signIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain!],
    redirectUri: "/",
    postLogoutRedirectUri: "/",
  },
};

// Scopes you add here will be prompted for consent during login
export const loginRequest: PopupRequest = {
  scopes: [process.env.REACT_APP_API_SCOPE!],
};

/**
 * Enter here the coordinates of your web API and scopes for access token request
 * The current application coordinates were pre-registered in a B2C tenant.
 */
export const apiConfig = {
  scopes: [process.env.REACT_APP_API_SCOPE!],
  uri: process.env.REACT_APP_API_SCOPE_URI,
};

/**
 * IMPORTANT: In case of overage, role list is cached for 1 hr by default, and thus cached groups
 * will miss any changes to a users group membership for this duration. A hard browser refresh will
 * be required to refresh roles
 */
//export const CACHE_TTL_IN_MS = 60 * 60 * 1000; // 1 hour in milliseconds

export const CACHE_TTL_IN_MS = 1; // 1 hour in milliseconds
