import {
  ColDef,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { OrderType, PricePointDetailDTO } from "../../../services/ApiClient";
import "./StoreWastage.scss";

interface IStoreWastageTableProps {
  wastageData: PricePointDetailDTO[];
}

interface IWastageRow extends Partial<PricePointDetailDTO> {
  label?: string;
  fullWidth?: boolean;
}

export const StoreWastageTable = (props: IStoreWastageTableProps) => {
  const [rowData, setRowdata] = useState<IWastageRow[] | null>(null);

  const rowLabelValueGetter = (params: ValueGetterParams) => {
    if (params.data.label) {
      return params.data.label;
    } else if (typeof params?.data?.pricePoint !== "undefined") {
      return "$" + params.data.pricePoint;
    }
    return null;
  };

  const currencyFormatter = useCallback((value: number) => {
    return "$" + formatNumber(value);
  }, []);

  const currencyValueFormatter = useCallback(
    (params: ValueFormatterParams) => {
      return currencyFormatter(params.value);
    },
    [currencyFormatter]
  );

  const percentageValueFormatter = useCallback(
    (params: ValueFormatterParams) => {
      return formatNumber(params.value) + "%";
    },
    []
  );

  const formatNumber = (number: number) => {
    return Math.floor(number)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "",
        headerClass: "header-group",
        marryChildren: true,
        children: [
          {
            headerName: "Price Point",
            field: "pricePoint",
            cellClass: "bold-first-column",
            valueGetter: rowLabelValueGetter,
            colSpan: (params: any) => {
              const fullWidth = params.data?.fullWidth;
              if (fullWidth) {
                return 8;
              } else {
                return 1;
              }
            },
            flex: 1,
          },
        ],
      },
      {
        headerName: "Number",
        headerClass: "header-group",
        marryChildren: true,
        children: [
          {
            headerName: "Delivered",
            field: "numDelivered",
            flex: 1,
          },
          {
            headerName: "Sold",
            field: "numSold",
            flex: 1,
          },
          {
            headerName: "Wasted",
            field: "numWasted",
            flex: 1,
          },
        ],
      },
      {
        headerName: "$",
        headerClass: "header-group",
        marryChildren: true,
        children: [
          {
            headerName: "Delivered",
            field: "amountDelivered",
            flex: 1,
            valueFormatter: currencyValueFormatter,
          },
          {
            headerName: "Sold",
            field: "amountSold",
            flex: 1,
            valueFormatter: currencyValueFormatter,
          },
          {
            headerName: "Wasted",
            field: "amountWasted",
            flex: 1,
            valueFormatter: currencyValueFormatter,
          },
        ],
      },
      {
        headerName: "%",
        headerClass: "header-group",
        marryChildren: true,
        children: [
          {
            headerName: "Wasted",
            field: "percentageWasted",
            flex: 1,
            valueFormatter: percentageValueFormatter,
          },
        ],
      },
    ],
    [currencyValueFormatter, percentageValueFormatter]
  );

  const getColumnCount = useCallback((rows: PricePointDetailDTO[]) => {
    const summary = rows.reduce((previousValue, currentValue) => {
      return {
        itemType: currentValue.itemType,
        pricePoint: currentValue.pricePoint,
        numDelivered: previousValue.numDelivered + currentValue.numDelivered,
        numSold: previousValue.numSold + currentValue.numSold,
        numWasted: previousValue.numWasted + currentValue.numWasted,
        amountDelivered:
          previousValue.amountDelivered + currentValue.amountDelivered,
        amountSold: previousValue.amountSold + currentValue.amountSold,
        amountWasted: previousValue.amountWasted + currentValue.amountWasted,
        percentageWasted:
          ((previousValue.amountWasted + currentValue.amountWasted) /
            (previousValue.amountSold + currentValue.amountSold)) *
          100,
      };
    });

    return summary;
  }, []);

  const getZeroRow = useCallback(() => {
    return {
      itemType: 0,
      pricePoint: 0,
      numDelivered: 0,
      numSold: 0,
      numWasted: 0,
      amountDelivered: 0,
      amountSold: 0,
      amountWasted: 0,
      percentageWasted: 0,
    };
  }, []);

  useEffect(() => {
    const cuts = props.wastageData.filter((row) => {
      return row.itemType === Object.values(OrderType).indexOf("Cuts");
    });
    const cutsTotal = cuts.length >= 1 ? getColumnCount(cuts) : getZeroRow();

    const pots = props.wastageData.filter((row) => {
      return row.itemType === Object.values(OrderType).indexOf("Pots");
    });
    const potsTotal = pots.length >= 1 ? getColumnCount(pots) : getZeroRow();

    const materials = props.wastageData.filter((row) => {
      return row.itemType === Object.values(OrderType).indexOf("Materials");
    });
    const materialsTotal =
      materials.length >= 1 ? getColumnCount(materials) : getZeroRow();

    const allItemsTotal =
      props.wastageData.length >= 1
        ? getColumnCount(props.wastageData)
        : getZeroRow();

    const rows: IWastageRow[] = [
      { label: "Cuts", fullWidth: true },
      ...cuts,
      {
        label: "Cuts total",
        ...cutsTotal,
      },
      { label: "Pots", fullWidth: true },
      ...pots,
      {
        label: "Pots total",
        ...potsTotal,
      },
      { label: "Materials", fullWidth: true },
      ...materials,
      {
        label: "Materials total",
        ...materialsTotal,
      },
      {
        label: "Overall total",
        ...allItemsTotal,
      },
    ];

    setRowdata(rows);
  }, [getColumnCount, getZeroRow, props.wastageData]);

  return (
    <div className={"store-kpis"}>
      <AgGridReact
        className={`ag-theme-alpine`}
        columnDefs={columnDefs}
        rowData={rowData}
        domLayout="autoHeight"
        columnHoverHighlight={true}
      />
    </div>
  );
};
