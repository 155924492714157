import { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useParams } from "react-router-dom";
import {
  ApiException,
  OrderClient,
  StoreDTO,
} from "../../../services/ApiClient";
import "./StoreKpis.scss";

interface IStoreKpiProps {}

export const StoreKpis = (props: IStoreKpiProps) => {
  const params = useParams();
  //const [error, setError] = useState<null | string>(null);
  //const [isLoaded, setIsLoaded] = useState(false);
  //const [isFetching, setIsFetching] = useState(false);
  const [kpiData, setKpiData] = useState<StoreDTO | null>(null);

  useEffect(() => {
    const fetchNewOrder = async () => {
      try {
        //setIsFetching(true);
        const kpiData = await new OrderClient(
          process.env.REACT_APP_API_BASE
        ).getStoreKPIs(params.storeid);
        setKpiData(kpiData);
        //setIsLoaded(true);
        //setIsFetching(false);
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        //setIsFetching(false);
        //setError(JSON.parse(error.response).error);
      }
    };

    fetchNewOrder().catch(console.error);
  }, [params.storeid]);

  return (
    <div className={"store-kpis"}>
      {kpiData && (
        <Row className="gy-5">
          <Col lg={12}>
            <Container>
              <h2 className="page-header">Store KPIs for {kpiData.name}</h2>
            </Container>
          </Col>
          <Col xs={12} lg={12}>
            <Container>
              <Card className="shadow-sm" border="light">
                <Card.Body className="available-products-card">
                  KPIs are currently unavailable in the new system. We're
                  working on getting them working ASAP.
                </Card.Body>
              </Card>
            </Container>
          </Col>
          {/*
              <Col lg={12}>
                <SingleCard pageTitle='Cuts'>
                  <StoreKpiTable 
                    kpiData={kpiData?.kpiCuts!}
                  />
                </SingleCard>              
              </Col>
              <Col lg={12}>
                <SingleCard pageTitle='Pots'>
                  <StoreKpiTable 
                    kpiData={kpiData?.kpiPots!}
                  />
                </SingleCard>                
              </Col>
              <Col lg={12}>
                <SingleCard pageTitle='Cuts & Pots'>
                  <StoreKpiTable 
                    kpiData={kpiData?.kpiComb!}
                  />
                </SingleCard>               
              </Col>             
        */}
        </Row>
      )}
    </div>
  );
};
