import React from "react";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import { OrderDetailDTO } from "../../../services/ApiClient";
import dayjs from "dayjs";
import { OrderDetails } from "../../common/orderDetails/OrderDetails";
import { BudgetSummary } from "./BudgetSummary";
import { NoCards } from "../../layouts/NoCards";
import { Card } from "react-bootstrap";

interface INewOrderProps {
  orderType: string;
  orderDate: string;
  orderData: OrderDetailDTO[];
  orderTotal: number;
  storeName: string;
  budget: number;
  budgetUsedWtd: number;
  budgetUsedMtd: number;
  saving: boolean;
  confirmOrder: () => void;
  backToOrder: () => void;
}

export const ConfirmOrder = (props: INewOrderProps) => {
  return (
    <NoCards
      pageTitle={
        props.storeName
          ? `Order ${props.orderType} for ${props.storeName} - ${dayjs(
              props.orderDate
            ).format("DD/MM/YYYY")}`
          : undefined
      }
      className={"new-order"}
    >
      {props.orderData.length > 0 ? (
        <>
          <Col lg={12}>
            <OrderDetails
              orderData={props.orderData.filter((order) => order.quantity > 0)}
              orderTotal={props.orderTotal!}
              updateRecieved={() => {}}
              setOrderData={() => {}}
              newOrder={true}
              showCredit={false}
            />
          </Col>
          <BudgetSummary
            orderTotal={props.orderTotal ?? 0}
            wtd={props.budgetUsedWtd + props.orderTotal ?? 0}
            mtd={props.budgetUsedMtd + props.orderTotal ?? 0}
            budget={props.budget ?? 0}
            orderType={props.orderType ?? ""}
            hideTitle={false}
          />
        </>
      ) : (
        <Col lg={12}>
          <Card className="shadow-sm" border="light">
            <div className="no-items">No items available.</div>
          </Card>
        </Col>
      )}
      <Col lg={12} className={"d-flex flex-row-reverse"}>
        <Stack gap={3} direction="horizontal">
          <Button type="button" variant="light" onClick={props.backToOrder}>
            {"Back"}
          </Button>
          <Button
            type="submit"
            onClick={props.confirmOrder}
            disabled={props.saving || props.orderData.length <= 0}
          >
            {props.saving ? "Saving" : "Save"}
          </Button>
        </Stack>
      </Col>
    </NoCards>
  );
};
