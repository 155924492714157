import { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  ApiException,
  EDeliveryMode,
  OrderClient,
  OrderDTO,
  OrderType,
  Pickpackstate,
  UnpickedViewDTO,
} from "../../../services/ApiClient";
import { CardPlaceholder } from "../../common/cardPlaceholder/CardPlaceholder";
import { NoCards } from "../../layouts/NoCards";
import { SingleCard } from "../../layouts/SingleCard";

interface IPickOrderProps {
  pick: boolean;
  pack: boolean;
}

export const NextOrder = (props: IPickOrderProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [noNextOrders, setNoNextOrders] = useState<boolean>(false);

  const navigateToPickOrder = useCallback(
    async (data: OrderDTO) => {
      if (props.pick) {
        await new OrderClient().pickPackOrder(
          data?.orderId,
          Pickpackstate.Picking,
          data.packedBox
        );
        navigate({
          pathname: `/pick/${data.orderId}`,
          search: `${searchParams.toString()}`,
        });
      } else if (props.pack) {
        await new OrderClient().pickPackOrder(
          data?.orderId,
          Pickpackstate.Packing,
          data.packedBox
        );
        navigate({
          pathname: `/pack/${data.orderId}`,
          search: `${searchParams.toString()}`,
        });
      }
    },
    [props.pick, props.pack, navigate, searchParams]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const deliveryType = searchParams.get("OrderType");
        const deliveryDate = searchParams.get("DeliveryDate");
        const deliveryMode = searchParams.get("DeliveryMode");
        const previousItemId = Number(searchParams.get("PreviousItemId"));

        let result: UnpickedViewDTO | null;

        if (props.pick) {
          result = await new OrderClient(
            process.env.REACT_APP_API_BASE
          ).getUnpickedOrders(
            OrderType[deliveryType as keyof typeof OrderType],
            EDeliveryMode[deliveryMode as keyof typeof EDeliveryMode],
            deliveryDate
          );
        } else if (props.pack) {
          result = await new OrderClient(
            process.env.REACT_APP_API_BASE
          ).unpackedOrders(
            OrderType[deliveryType as keyof typeof OrderType],
            EDeliveryMode[deliveryMode as keyof typeof EDeliveryMode]
          );
        }

        //Exclude current item and items currently being picked/packed
        let filteredItems = result!.orders!.filter((x) => {
          if (x.orderId === previousItemId) {
            return false;
          }
          if (props.pick && x.pickingBy !== null) {
            return false;
          }
          if (props.pack && x.packingBy !== null) {
            return false;
          }
          return true;
        });

        if (filteredItems!.length > 0) {
          navigateToPickOrder(filteredItems[0]);
        } else {
          setNoNextOrders(true);
        }
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        //setError(JSON.parse(error.response).error);
      }
    };

    fetchData().catch(console.error);
  }, [navigateToPickOrder, props, searchParams]);

  return (
    <NoCards>
      <Row className={"g-4 pick-orders"}>
        <Col lg={12} className={""}>
          <SingleCard pageTitle={`Next order`} fluid={true} noHeader={true}>
            {!noNextOrders ? (
              <CardPlaceholder />
            ) : (
              <div style={{ padding: "16px" }}>
                No more orders found for the current day
                <br />
                <br />
                <Button
                  onClick={() =>
                    navigate({
                      pathname: `/${props.pack ? "pack" : "pick"}`,
                      search: `${searchParams.toString()}`,
                    })
                  }
                >
                  Back to home
                </Button>
              </div>
            )}
          </SingleCard>
        </Col>
      </Row>
    </NoCards>
  );
};
