import { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useParams } from "react-router-dom";
import {
  ApiException,
  OrderClient,
  PricePointsDTO,
} from "../../../services/ApiClient";
import { SingleCard } from "../../layouts/SingleCard";
import "./StoreWastage.scss";
import { StoreWastageTable } from "./StoreWastageTable";

interface IStoreWastageProps {}

export const StoreWastage = (props: IStoreWastageProps) => {
  const params = useParams();
  //const [error, setError] = useState<null | string>(null);
  //const [isLoaded, setIsLoaded] = useState(false);
  //const [isFetching, setIsFetching] = useState(false);
  const [wastageData, setwastageData] = useState<PricePointsDTO | null>(null);

  useEffect(() => {
    const fetchNewOrder = async () => {
      try {
        //setIsFetching(true);
        const wastageData = await new OrderClient(
          process.env.REACT_APP_API_BASE
        ).getStoreWastage(params.storeid);
        setwastageData(wastageData);
        //setIsLoaded(true);
        //setIsFetching(false);
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        //setIsFetching(false);
        //setError(JSON.parse(error.response).error);
      }
    };

    fetchNewOrder().catch(console.error);
  }, [params.storeid]);

  return (
    <div className={"store-wastage"}>
      {wastageData && (
        <Row className="gy-5">
          <Col lg={12}>
            <Container>
              <h2 className="page-header">
                Store wastage - {wastageData.storeName}
              </h2>
            </Container>
          </Col>
          <Col lg={12}>
            <SingleCard pageTitle="Last week">
              <StoreWastageTable wastageData={wastageData.lastWeek} />
            </SingleCard>
          </Col>
          <Col lg={12}>
            <SingleCard pageTitle="Prev week">
              <StoreWastageTable wastageData={wastageData.prevWeek} />
            </SingleCard>
          </Col>
          <Col lg={12}>
            <SingleCard pageTitle="4 weeks">
              <StoreWastageTable wastageData={wastageData.fourWeek} />
            </SingleCard>
          </Col>
        </Row>
      )}
    </div>
  );
};
