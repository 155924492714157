import { ICellRendererParams } from "ag-grid-community";
import { useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export const CustomNameWastageRenderer = (
  props: ICellRendererParams
): JSX.Element => {
  const [aboveWaste, setAboveWaste] = useState<boolean>(false);
  const [wasteageTarget, setWaste] = useState<string>("");

  useEffect(() => {
    if (props.data.orderType === 0) {
      //pots
      setWaste(props.data.store.wastageTarget);
      if (
        props.data.store.wastageTarget > 0 &&
        props.data.store.kpiPots.shrinkage8WeekAverage >
          props.data.store.wastageTarget
      ) {
        setAboveWaste(true);
      }
    }

    if (props.data.orderType === 1) {
      //cuts
      setWaste(props.data.store.wastageTarget);
      if (
        props.data.store.wastageTarget > 0 &&
        props.data.store.kpiCuts.shrinkage8WeekAverage >
          props.data.store.wastageTarget
      ) {
        setAboveWaste(true);
      }
    }
  }, [
    props.data.orderType,
    props.data.store.kpiCuts.shrinkage8WeekAverage,
    props.data.store.kpiPots.shrinkage8WeekAverage,
    props.data.store.wastageTarget,
  ]);

  return (
    <OverlayTrigger
      key={"top"}
      placement={"top"}
      overlay={
        <Tooltip id={`tooltip-wastage`}>
          <strong>{wasteageTarget}%</strong>
        </Tooltip>
      }
    >
      <div className={aboveWaste ? "order-title-above-waste" : ""}>
        {props.valueFormatted ? props.valueFormatted : props.value}
      </div>
    </OverlayTrigger>
  );
};
