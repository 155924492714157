import { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { useNavigate, useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import {
  ApiException,
  OrderClient,
  StoreNameDTO,
  UserClient,
  UserDTO,
} from "../../../services/ApiClient";
import { NoCards } from "../../layouts/NoCards";
import { UserEditForm } from "./UserEditForm";
import "./Users.scss";

interface IUserNewProps {}

export const UserNew = (props: IUserNewProps) => {
  const params = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState<null | string>(null);
  const [isSaving, setIsSaving] = useState(false);
  //const [isLoaded, setIsLoaded] = useState(false);
  //const [isFetching, setIsFetching] = useState(false);
  const [userData, setUserData] = useImmer<UserDTO | null>(null);
  const [stores, setStores] = useImmer<StoreNameDTO[] | null>(null);

  useEffect(() => {
    const newUser = {
      email: "",
      name: "",
      kooTraderRole: 0,
      storeIDs: [],
    } as any;

    setUserData(newUser);

    const fetchData = async () => {
      try {
        //setIsFetching(true);
        const stores = await new OrderClient(
          process.env.REACT_APP_API_BASE
        ).getStoreNames();
        setStores(stores);
        //setIsLoaded(true);
        //setIsFetching(false);
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        //setIsFetching(false);
        setError(JSON.parse(error.response).error);
      }
    };

    fetchData().catch(console.error);
  }, [params, setStores, setUserData]);

  const saveUser = useCallback(
    async (newUserData: UserDTO) => {
      try {
        setIsSaving(true);
        if (newUserData) {
          await new UserClient(process.env.REACT_APP_API_BASE).saveUser(
            newUserData
          );
        }
        setIsSaving(false);
        navigate("/settings/users");
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        setError(JSON.parse(error.response).error);
        setIsSaving(false);
      }
    },
    [navigate]
  );

  return (
    <div className={"portal-settings"}>
      <NoCards pageTitle="">
        <>
          <Col md={12}>
            <h3>New user</h3>
          </Col>
          <Col lg={12}>
            <Card className="shadow-sm p-0" border="light">
              <Card.Body className="p-0 portal-settings-form">
                {typeof userData !== "undefined" && userData && stores && (
                  <UserEditForm
                    user={userData}
                    updateUser={saveUser}
                    isNew={true}
                    isSaving={isSaving}
                    stores={stores}
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
        </>
      </NoCards>
      <Modal show={error?.length! > 0} onHide={() => setError(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setError(null)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
