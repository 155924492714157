import { Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import { LinkContainer } from "react-router-bootstrap";
import { OrderDetailDTO } from "../../../services/ApiClient";
import { NoCards } from "../../layouts/NoCards";
import { OrderItem } from "../newOrders/OrderItem";
import "./NewTransfer.scss";

interface INewTransferProps {
  orderDetails: OrderDetailDTO[];
  totalOrderQty: number;
  orderType: string;
  setItemQty: (itemId: number, quanity: number, price: number) => void;
  updateItemTotalQty: (qtyToAdd: number) => void;
  setItemNote: (
    itemId: number,
    value: string | "undefined",
    price: number
  ) => void;
  setItemPrice: (itemId: number, price: number, oldPrice: number) => void;
  confirmOrder: () => void;
}

export const NewTransferForm = (props: INewTransferProps) => {
  return (
    <>
      <NoCards pageTitle={""}>
        <>
          {props.orderDetails.length >= 0 ? (
            <>
              {props.orderDetails?.map((orderDetail, index) => (
                <Col lg={12} key={`orderitem${index}`}>
                  <Card className="shadow-sm" border="light">
                    <OrderItem
                      itemId={orderDetail.itemId!}
                      name={orderDetail.item?.itemName!}
                      type={props.orderType}
                      description={orderDetail.item?.message!}
                      quantity={orderDetail.quantity}
                      increment={
                        orderDetail.item?.incrementBy! > 0
                          ? orderDetail.item?.incrementBy!
                          : 1
                      }
                      image={orderDetail.item?.photo!}
                      price={orderDetail.unitPrice!}
                      qtyAvailable={
                        (orderDetail.item?.stocktake ?? 0) +
                        (orderDetail.item?.incoming ?? 0)
                      }
                      updateItemQty={props.setItemQty}
                      updateItemTotalQty={props.updateItemTotalQty}
                      updateItemPrice={props.setItemPrice}
                      boxLot={orderDetail.item?.boxlot === 1}
                      special={orderDetail.item?.special === 1}
                      multibuy={orderDetail.item?.multiBuy === 1}
                      multibuyNote={orderDetail.item?.multiBuyNote!}
                      avilability={orderDetail.item?.availability!}
                      updateItemNote={props.setItemNote}
                      preorder={orderDetail.preOrder > 0}
                      requiredNote={
                        orderDetail.item?.itemName
                          ?.toLowerCase()
                          .includes("Miscellaneous".toLowerCase())!
                      }
                      requiredPrice={
                        orderDetail.item?.itemName
                          ?.toLowerCase()
                          .includes("Miscellaneous".toLowerCase())!
                      }
                      note={orderDetail.comment}
                      directQtyEdit={true}
                    />
                  </Card>
                </Col>
              ))}
            </>
          ) : (
            <Col lg={12}>
              <Card className="shadow-sm" border="light">
                <div className="no-items">No items available.</div>
              </Card>
            </Col>
          )}
        </>
        <Col lg={12} className={"d-flex flex-row-reverse"}>
          <Stack gap={3} direction="horizontal">
            <LinkContainer to={`/`}>
              <Button type="button" variant="light">
                {"Cancel"}
              </Button>
            </LinkContainer>

            <Button
              type="submit"
              disabled={props.totalOrderQty <= 0}
              onClick={() => props.confirmOrder()}
            >
              {"Confirm"}
            </Button>
          </Stack>
        </Col>
      </NoCards>
    </>
  );
};
