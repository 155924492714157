import { ICellRendererParams, ValueFormatterParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { OrderDetailDTO } from "../../../services/ApiClient";
import { CheckboxRenderer } from "../../pages/currentOrders/CheckboxRenderer";
import { CreateCreditRequest } from "../../pages/currentOrders/CreateCreditRequest";
import "./OrderDetails.scss";

interface IOrderDetailsProps {
  orderTotal: number;
  orderData: OrderDetailDTO[];
  updateRecieved: (
    currentOrderID: number,
    currentOrderDetailID: number,
    value: boolean
  ) => void;
  setOrderData: (currentOrder: OrderDetailDTO[] | null) => void;
  newOrder: boolean;
  showCredit: boolean;
}

export const OrderDetails = (props: IOrderDetailsProps) => {
  const [orderDetailItem, setOrderDetailItem] = useState<OrderDetailDTO | null>(
    null
  );

  const currencyFormatter = useCallback((value: number) => {
    return "$" + formatNumber(value);
  }, []);

  const currencyValueFormatter = useCallback(
    (params: ValueFormatterParams) => {
      return currencyFormatter(params.value);
    },
    [currencyFormatter]
  );

  const formatNumber = (number: number) => {
    return number.toFixed(2);
  };

  const totalValueFormatter = useCallback(
    (params: ValueFormatterParams) => {
      return currencyFormatter(params.data.quantity * params.data.unitPrice);
    },
    [currencyFormatter]
  );

  const setOrderDetails = useCallback(
    (data: OrderDetailDTO) => {
      setOrderDetailItem(data);
    },
    [setOrderDetailItem]
  );

  const CreditRequestRenderer = useCallback(
    (props: ICellRendererParams): JSX.Element => {
      return (
        <div
          className={"btn-container d-flex align-items-center"}
          style={{ height: "100%" }}
        >
          {!props.data.deleted && !props.data.creditItem && (
            <Button
              size="sm"
              variant="primary"
              onClick={() => setOrderDetails(props.data)}
            >
              {"Request Credit"}
            </Button>
          )}
        </div>
      );
    },
    [setOrderDetails]
  );

  const columnDefs = useMemo(
    () => [
      {
        headerName: "Item",
        flex: 4,
        field: "item.itemName",
        wrapText: true,
        autoHeight: true,
        cellStyle: {
          lineHeight: 1,
          "padding-top": 4,
          "padding-bottom": 4,
          display: "flex",
          "align-items": "center",
        },
        minWidth: 150,
      },
      {
        headerName: "Qty",
        field: "quantity",
        type: "number",
        maxWidth: 60,
      },
      {
        headerName: "Retail",
        field: "unitPrice",
        type: "number",
        valueFormatter: currencyValueFormatter,
        maxWidth: 90,
      },
      {
        headerName: "Total",
        field: "total",
        type: "number",
        valueFormatter: totalValueFormatter,
        maxWidth: 90,
      },
      ...(!props.newOrder && props.showCredit
        ? [
            {
              headerName: "Confirm",
              field: "received",
              cellRenderer: CheckboxRenderer,
              cellRendererParams: {
                updateRecieved: props.updateRecieved,
              },
              maxWidth: 90,
              minWidth: 90,
            },
            {
              headerName: "Credit",
              field: "creditItem",
              flex: 4,
              cellRenderer: CreditRequestRenderer,
              maxWidth: 140,
              minWidth: 140,
            },
          ]
        : []),
    ],
    [
      props.newOrder,
      props.showCredit,
      props.updateRecieved,
      currencyValueFormatter,
      totalValueFormatter,
      CreditRequestRenderer,
    ]
  );

  const getRowClass = (params: any) => {
    if (params.data.deleted) {
      return "item-deleted";
    }
  };

  const orderTotal = useMemo(() => {
    return props.orderData.reduce((total, item) => {
      if (item.deleted) return total;
      return total + item.quantity * item.unitPrice;
    }, 0);
  }, [props.orderData]);

  return (
    <>
      <Card className="shadow-sm order-details-table" border="light">
        <AgGridReact
          className={`ag-theme-alpine`}
          columnDefs={columnDefs}
          rowData={props.orderData}
          domLayout="autoHeight"
          getRowClass={getRowClass}
        />
        <div className={"total-row"}>
          <strong>Total order value is {currencyFormatter(orderTotal!)}</strong>
        </div>
      </Card>
      {orderDetailItem !== null && (
        <CreateCreditRequest
          orderDetailItem={orderDetailItem}
          setOrderData={props.setOrderData}
          setOrderDetailItem={setOrderDetailItem}
        />
      )}
    </>
  );
};
