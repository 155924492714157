import { RowClickedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { FieldHookConfig, useField, useFormikContext } from "formik";
import { useCallback, useMemo, useRef, useState } from "react";
import { AgGridReact as AgGridReactType } from "ag-grid-react/lib/agGridReact";

import "./TableSelect.scss";

interface ITableSelectProps {
  columnName: string;
  columnKey: string;
  returnValue: string;
  values: any[];
  selectedValues: any[];
  height: number;
  singleSelect?: boolean;
  additionalDisplayColumnId?: string;
  additionalDisplayColumnName?: string;
}

export const TableSelect = (
  props: ITableSelectProps & FieldHookConfig<string>
) => {
  const { setFieldValue, setTouched } = useFormikContext();
  const [field] = useField(props);
  const gridRef = useRef<AgGridReactType>(null);
  const [gridData] = useState<any[]>(props.values);

  const setSelected = useCallback(() => {
    if (gridRef.current?.api) {
      if (props.selectedValues && props.selectedValues.length > 0) {
        gridRef.current?.api?.forEachNode((node) => {
          const isSelected = props.selectedValues?.some((rowObj) => {
            return rowObj === node.data[props.returnValue];
          });

          node.setSelected(isSelected);
        });
      }
    }
  }, [props.selectedValues, props.returnValue, gridRef]);

  const columnDefs = useMemo(
    () => [
      ...(props.additionalDisplayColumnId
        ? [
            {
              headerName: props.additionalDisplayColumnName,
              field: props.additionalDisplayColumnId,
              filter: "agTextColumnFilter",
              floatingFilter: true,
              sortable: true,
            },
          ]
        : []),
      {
        headerName: props.columnName,
        field: props.columnKey,
        flex: 1,
        checkboxSelection: props.singleSelect ? false : true,
        headerCheckboxSelection: props.singleSelect ? false : true,
        filter: "agTextColumnFilter",
        floatingFilter: true,
        sortable: true,
      },
    ],
    [
      props.additionalDisplayColumnId,
      props.additionalDisplayColumnName,
      props.columnKey,
      props.columnName,
      props.singleSelect,
    ]
  );

  const onRowClicked = useCallback(
    (event: RowClickedEvent) => {
      const rows = event.api.getSelectedRows();
      setTouched(field.name);

      if (rows.length === 0) {
        setTouched(field.name);
        setFieldValue(field.name, null);
      }

      if (props.singleSelect) {
        if (
          rows.length > 0 &&
          typeof rows[0][props.returnValue] !== "undefined"
        ) {
          setFieldValue(field.name, rows[0][props.returnValue]);
        }
      } else {
        const updateArray = rows.map((row) => {
          return row[props.returnValue];
        });
        setFieldValue(field.name, updateArray);
      }
    },
    [
      field.name,
      props.returnValue,
      props.singleSelect,
      setFieldValue,
      setTouched,
    ]
  );

  return (
    <div
      className="ag-theme-alpine table-select"
      style={{ height: `${props.height}px`, width: "100%" }}
    >
      <AgGridReact
        ref={gridRef}
        columnDefs={columnDefs}
        rowData={gridData}
        onRowSelected={onRowClicked}
        rowSelection={props.singleSelect ? "single" : "multiple"}
        pagination={false}
        paginationPageSize={100}
        rowMultiSelectWithClick={true}
        onGridReady={setSelected}
      />
    </div>
  );
};
