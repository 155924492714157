import { Formik } from "formik";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import { LinkContainer } from "react-router-bootstrap";
import {
  EStoreType,
  OrderType,
  StoreTypeBudgetMultiplier,
} from "../../../services/ApiClient";

interface IBudgetMultiplierFormProps {
  budgetMultiplier: StoreTypeBudgetMultiplier;
  updateBudgetMultiplier: (availabilityData: StoreTypeBudgetMultiplier) => void;
  deleteMultiplier?: (availabilityData: StoreTypeBudgetMultiplier) => void;
  isNew: boolean;
  isSaving: boolean;
}

export const BudgetMultiplierForm = (props: IBudgetMultiplierFormProps) => {
  return (
    <div className={"portal-settings-form"}>
      <Formik
        //validationSchema={schema}
        onSubmit={props.updateBudgetMultiplier}
        //onSubmit={console.log}
        initialValues={props.budgetMultiplier}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          dirty,
          errors,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="">
              <Form.Group className="mb-3">
                <Form.Label>Store type</Form.Label>
                <Form.Select
                  onChange={handleChange}
                  id={"storeType"}
                  name={"storeType"}
                  value={values.storeType}
                >
                  {Object.keys(EStoreType)
                    .filter((x) => !parseInt(x) && x !== "0")
                    .map((key: string) => (
                      <option
                        key={key}
                        value={EStoreType[key as keyof typeof EStoreType]}
                      >
                        {key}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Order type</Form.Label>
                <Form.Select
                  onChange={handleChange}
                  id={"ordertype"}
                  name={"ordertype"}
                  value={values.ordertype}
                >
                  {Object.keys(OrderType)
                    .filter((x) => !parseInt(x) && x !== "0")
                    .map((key: string) => (
                      <option
                        key={key}
                        value={OrderType[key as keyof typeof OrderType]}
                      >
                        {key}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Effective from</Form.Label>
                <Form.Control
                  type="date"
                  name="from"
                  onChange={handleChange}
                  value={values.from?.substring(0, 10)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Effective to</Form.Label>
                <Form.Control
                  type="date"
                  name="to"
                  onChange={handleChange}
                  value={values.to?.substring(0, 10)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Percentage increase (%)</Form.Label>
                <InputGroup>
                  <InputGroup.Text>%</InputGroup.Text>
                  <Form.Control
                    name={"pctIncrease"}
                    type="number"
                    placeholder="0.00"
                    defaultValue={values.pctIncrease}
                    onChange={handleChange}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group>
                <Stack gap={3} direction="horizontal">
                  <LinkContainer to={"/settings"}>
                    <Button type="button" variant="light">
                      {"Cancel"}
                    </Button>
                  </LinkContainer>
                  {typeof props.deleteMultiplier !== "undefined" &&
                    !props.isNew && (
                      <Button
                        type="button"
                        disabled={props.isSaving}
                        variant="light"
                        onClick={() => props.deleteMultiplier!(values)}
                      >
                        {"Delete"}
                      </Button>
                    )}
                  <Button type="submit" disabled={props.isSaving || !dirty}>
                    {props.isSaving ? "Saving" : "Save"}
                  </Button>
                </Stack>
              </Form.Group>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};
