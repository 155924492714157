import { ICellRendererParams } from "ag-grid-community";
import React, { useCallback } from "react";

export const CheckboxRenderer = (props: ICellRendererParams) => {
  const onClick = useCallback(
    (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
      const checked = event.currentTarget.checked;
      let colId = props.column?.getColId();
      if (typeof colId !== "undefined") {
        if (checked) {
          props.node.setDataValue(colId, 1);
        } else {
          props.node.setDataValue(colId, 0);
        }
      }
    },
    [props.column, props.node]
  );

  return (
    <input type="checkbox" onClick={onClick} defaultChecked={props.value > 0} />
  );
};
