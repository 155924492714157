import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import { NoCards } from "../../layouts/NoCards";
import { BudgetMultipliers } from "./BudgetMultipliers";
import { PortalSettingsForm } from "./PortalSettingsForm";

import "./PortalSettings.scss";

interface IPortalSettingsProps {}

export const PortalSettings = (props: IPortalSettingsProps) => {
  return (
    <div className={"portal-settings"}>
      <NoCards pageTitle="Portal settings">
        <Col lg={12}>
          <Card className="shadow-sm p-0" border="light">
            <Card.Body className="p-0 portal-settings-form">
              <PortalSettingsForm />
            </Card.Body>
          </Card>
        </Col>
        <Col lg={12}>
          <BudgetMultipliers />
        </Col>
      </NoCards>
    </div>
  );
};
