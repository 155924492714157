import React from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { EDeliveryMode } from "../../../services/ApiClient";

interface IDeliveryModePickerProps {
  selected: EDeliveryMode;
  size: "sm" | "lg" | undefined;
  defaultValue: EDeliveryMode;
  setDeliveryMode: (delvieryMode: string) => void;
}

export const DeliveryModePicker = (props: IDeliveryModePickerProps) => {
  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    //const deliveryMode = EDeliveryMode[event.target.value as keyof typeof EDeliveryMode]; //fsck typescript (╯°□°）╯
    props.setDeliveryMode(event.target.value);
  };

  return (
    <InputGroup>
      <InputGroup.Text>Delivery mode</InputGroup.Text>
      <Form.Select
        onChange={onChange}
        id={"deliveryDate"}
        size={props.size}
        defaultValue={props.defaultValue}
      >
        {Object.keys(EDeliveryMode)
          .filter((x) => !parseInt(x) && x !== "0")
          .map(
            (
              key: string, //no clue wtf typescript is doing here
            ) => (
              <option key={key} value={key}>
                {key}
              </option>
            ),
          )}
      </Form.Select>
    </InputGroup>
  );
};
