import { AgGridReact } from "ag-grid-react";
import { useCallback, useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import { LinkContainer } from "react-router-bootstrap";
import { useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import { RoleContext } from "../../../App";
import {
  ApiException,
  ApprovalViewDTO,
  DeliveryDateDTO,
  EDeliveryMode,
  ManagerClient,
  OrderClient,
  OrderDTO,
  TeamClient,
  UserDTO,
  UserRole,
} from "../../../services/ApiClient";
import { CardPlaceholder } from "../../common/cardPlaceholder/CardPlaceholder";
import { DeliveryDatePicker } from "../../common/deliveryDatePicker/DeliveryDatePicker";
import { DeliveryModePicker } from "../../common/deliveryModePicker/DeliveryModePicker";
import { SingleCard } from "../../layouts/SingleCard";
import { MissingOrders } from "./MissingOrders";
import { OrderTable } from "./OrderTable";
import "./Orders.scss";
import { RetailManagerPicker } from "./RegionalManagerPicker";
import { SummaryTable } from "./SummaryTable";
import { ZeroTable } from "./ZeroTable";

interface IOrdersProps {}

export const Orders = (props: IOrdersProps) => {
  const [error, setError] = useState<null | string>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [approvalData, setApprovalData] = useImmer<ApprovalViewDTO | undefined>(
    undefined
  );
  const [ordersRef, setOrdersRef] = useState<AgGridReact<any> | null>(null); //we need to store the ref in state so that we can re-render once the header table is created
  const [summaryRef, setSummaryRef] = useState<AgGridReact<any> | null>(null);
  const [zeroRef, setZeroRef] = useState<AgGridReact<any> | null>(null);
  const [possibleDeilveryDates, setPossibleDeliveryDates] = useState<
    DeliveryDateDTO[]
  >([]);
  const [currentDeliveryDate, setCurrentDeliveryDate] =
    useState<DeliveryDateDTO | null>(null);
  const [currentDeliveryMode, setCurrentDeliveryMode] = useState<
    EDeliveryMode | undefined
  >(undefined);
  const [currentRetailManager, setCurrentRetailManager] = useState<
    UserDTO | undefined
  >(undefined);

  const [shouldUpdate, setShouldUpate] = useState<boolean>(false);
  const [orderType, setOrderType] = useState<number | undefined>(undefined);
  const [showPackingError, setShowPackingError] = useState<boolean>(false);
  const [confirming, setConfirming] = useState<boolean>(false);

  const params = useParams();
  const role = useContext(RoleContext)?.role;

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const orderType = params.orderType;
        let orderTypeIndex;

        //Pots = 0,
        //Cuts = 1,
        //Materials = 2,

        if (orderType === "pots") {
          orderTypeIndex = 0;
        } else if (orderType === "cut") {
          orderTypeIndex = 1;
        } else if (orderType === "materials") {
          orderTypeIndex = 2;
        }

        setOrderType(orderTypeIndex);
        setIsFetching(true);

        /*
        export enum UserRole {
            Merchandiser = 0,
            RetailManager = 1,
            Manager = 2,
            InStoreStaff = 3,
            RegionalManager = 4,
        }
        */

        if (role === 1 || role === 4) {
          const approvalViewResult = await new TeamClient(
            process.env.REACT_APP_API_BASE
          ).teamLeaderReviewOrdersGET(
            orderTypeIndex,
            currentDeliveryDate?.actualValue,
            currentRetailManager?.email
          );
          setApprovalData(approvalViewResult);
          setPossibleDeliveryDates(approvalViewResult.possibleDeliveryDates);
        } else {
          const approvalViewResult = await new ManagerClient(
            process.env.REACT_APP_API_BASE
          ).managerReviewOrdersGET(
            orderTypeIndex,
            currentDeliveryMode,
            currentDeliveryDate?.actualValue
          );
          setApprovalData(approvalViewResult);
          setPossibleDeliveryDates(approvalViewResult.possibleDeliveryDates);
        }

        setIsLoaded(true);
        setIsFetching(false);
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        setIsFetching(false);
        setError(JSON.parse(error.response).error);
      }
    };

    fetchOrders().catch(console.error);
  }, [
    currentDeliveryDate,
    currentDeliveryMode,
    currentRetailManager,
    params,
    role,
    setApprovalData,
  ]);

  const setDeliveryDate = useCallback(
    (delvieryDate: DeliveryDateDTO) => {
      setCurrentDeliveryDate(delvieryDate);
    },
    [setCurrentDeliveryDate]
  );

  const setDeliveryMode = useCallback((deliveryMode: string) => {
    const value = EDeliveryMode[deliveryMode as keyof typeof EDeliveryMode];
    setCurrentDeliveryMode(value);
  }, []);

  const setRegionalManager = useCallback((user: UserDTO) => {
    setCurrentRetailManager(user);
  }, []);

  //update in background
  useEffect(() => {
    if (shouldUpdate) {
      const updateOrders = async () => {
        try {
          if (typeof approvalData !== "undefined") {
            //setIsUpdating(true);
            if (role === 1 || role === 4) {
              await new TeamClient(
                process.env.REACT_APP_API_BASE
              ).teamLeaderReviewOrdersPOST(approvalData!);
            } else {
              await new ManagerClient(
                process.env.REACT_APP_API_BASE
              ).managerReviewOrdersPOST(approvalData!);
            }
            setShouldUpate(false);
            setConfirming(false);
          }
        } catch (e: unknown) {
          const error = e as ApiException;
          console.log(error.message);
          //setIsUpdating(false);
          setError(JSON.parse(error.response).error);
        }
      };

      updateOrders().catch(console.error);
    }
  }, [
    approvalData,
    shouldUpdate,
    currentDeliveryMode,
    currentDeliveryDate?.actualValue,
    orderType,
    role,
  ]);

  const updateStockTake = useCallback(
    async (recId: number, newValue: number) => {
      setApprovalData((draft) => {
        const index = draft?.availableItems.findIndex(
          (item) => item.recId === recId
        );
        if (index !== -1) {
          draft!.availableItems[index!].stocktake = newValue;
        }
      });
      setShouldUpate(true);
    },
    [setApprovalData, setShouldUpate]
  );

  const updateIncomming = useCallback(
    async (recId: number, newValue: number) => {
      setApprovalData((draft) => {
        const index = draft?.availableItems.findIndex(
          (item) => item.recId === recId
        );
        if (index !== -1) {
          draft!.availableItems[index!].incoming = newValue;
        }
      });
      setShouldUpate(true);
    },
    [setApprovalData, setShouldUpate]
  );

  const updateOrderState = useCallback(
    async (
      orderId: number,
      orderItemRecId: number,
      orderDetailId: number,
      newValue: number,
      oldValue: number,
      picking?: number,
      picked?: number
    ) => {
      setApprovalData((draft) => {
        const orderIndex = draft?.orders.findIndex(
          (order) => order.orderId === orderId
        );
        if (orderIndex !== -1) {
          const orderItemIndex = draft?.orders[
            orderIndex!
          ].orderDetails.findIndex(
            (item) => item.item?.recId === orderItemRecId
          );
          if (orderItemIndex !== -1) {
            draft!.orders[orderIndex!].total =
              draft!.orders[orderIndex!].total +
              (newValue - oldValue) *
                draft!.orders[orderIndex!].orderDetails[orderItemIndex!]
                  .unitPrice;
            draft!.orders[orderIndex!].orderDetails[orderItemIndex!].quantity =
              newValue;
            draft!.orders[orderIndex!].orderDetails[
              orderItemIndex!
            ].orderDetailId = orderDetailId;

            if (picking !== undefined) {
              draft!.orders[orderIndex!].picking = picking;
            }

            if (picked !== undefined) {
              draft!.orders[orderIndex!].picked = picked;
            }
          }
        }
      });
    },
    [setApprovalData]
  );

  const updateOrderDetailId = useCallback(
    async (orderId: number, orderItemRecId: number, orderDetailId: number) => {
      setApprovalData((draft) => {
        const orderIndex = draft?.orders.findIndex(
          (order) => order.orderId === orderId
        );
        if (orderIndex !== -1) {
          const orderItemIndex = draft?.orders[
            orderIndex!
          ].orderDetails.findIndex(
            (item) => item.item?.recId === orderItemRecId
          );
          if (orderItemIndex !== -1) {
            draft!.orders[orderIndex!].orderDetails[
              orderItemIndex!
            ].orderDetailId = orderDetailId;
          }
        }
      });
    },
    [setApprovalData]
  );

  const updateOrderLineStats = useCallback(
    (orderLine: OrderDTO) => {
      setApprovalData((draft) => {
        //Update Total Ordered
        orderLine.orderDetails.map((od) => {
          const availabilityItemIndex = draft?.availableItems.findIndex(
            (ai) => ai.recId === od.itemId
          );
          if (availabilityItemIndex !== -1) {
            draft!.availableItems[availabilityItemIndex!].totalOrdered =
              orderLine.totalOrderedItemsQty.find((t) => t.itemID === od.itemId)
                ?.totalOrdered ?? 0;
          }
          return draft;
        });

        //Update invididual lines
        const orderIndex = draft?.orders.findIndex(
          (order) => order.orderId === orderLine.orderId
        );
        if (orderIndex !== -1) {
          draft!.orders[orderIndex!].total = orderLine.total;
          draft!.orders[orderIndex!].teamLeadReviewed =
            orderLine.teamLeadReviewed;
          draft!.orders[orderIndex!].managerChanged = orderLine.managerChanged;
          draft!.orders[orderIndex!].used = orderLine.used;
          draft!.orders[orderIndex!].store = orderLine.store;
        }
      });
      //setShouldUpate(true);
    },
    [setApprovalData]
  );

  const updateOrder = useCallback(
    async (
      orderDetailId: number,
      orderId: number,
      orderItemRecId: number,
      newValue: number,
      oldValue: number
    ) => {
      try {
        if (typeof approvalData !== "undefined") {
          //setIsUpdating(true);
          updateOrderState(
            orderId,
            orderItemRecId,
            orderDetailId,
            newValue,
            oldValue
          ); //update state immedaitely as callbacks too slow

          const updateResult = await new OrderClient(
            process.env.REACT_APP_API_BASE
          ).saveOrderDetail({
            orderDetailId: orderDetailId, // 0 if new order detail
            orderId: orderId,
            itemId: orderItemRecId,
            quantity: newValue,
            unitPrice:
              approvalData?.availableItems.find(
                (ai) => ai.recId === orderItemRecId
              )?.price ?? 0,
          } as any);

          updateOrderLineStats(updateResult); //update stats based on response from api

          if (orderDetailId === 0) {
            const newOrderDetail = updateResult.orderDetails.find(
              (od) => od.item?.recId === orderItemRecId
            );

            if (newOrderDetail) {
              updateOrderDetailId(
                orderId,
                orderItemRecId,
                newOrderDetail.orderDetailId
              ); //Update order item id to prevent duplicates being created
            }
          }

          //setIsUpdating(false);
        }
      } catch (e: unknown) {
        const error = e as ApiException;

        //If the error is 409, the item is locked for picking/packing. Revert the state and line stats.
        if (error.status === 409) {
          const response = JSON.parse(error.response) as OrderDTO;
          updateOrderState(
            orderId,
            orderItemRecId,
            orderDetailId,
            oldValue,
            oldValue,
            response.picking,
            response.picked
          );
          setShowPackingError(true);
        }

        //setIsUpdating(false);
        setError(JSON.parse(error.response).error);
      }
    },
    [updateOrderState, updateOrderLineStats, approvalData, updateOrderDetailId]
  );

  const confirmOrders = useCallback(() => {
    try {
      if (typeof approvalData !== "undefined") {
        setConfirming(true);
        setApprovalData((draft) => {
          draft!.confirmed = true;
          draft?.orders.forEach((order, index) => {
            if (draft.orders[index].total > 0) {
              draft.orders[index].confirmed = 1;
            }
          });
        });
        setShouldUpate(true);
      }
    } catch (e: unknown) {
      const error = e as ApiException;
      console.log(error.message);
      //setIsUpdating(false);
      setError(JSON.parse(error.response).error);
    }
  }, [setApprovalData, setShouldUpate, approvalData]);

  const zeroOrders = useCallback(
    async (itemRecId: number) => {
      setApprovalData((draft) => {
        let quantityToRemove = 0;

        draft?.orders.forEach((order, orderIndex) => {
          order.orderDetails.forEach((orderDetail, orderDetailIndex) => {
            if (orderDetail.item?.recId === itemRecId) {
              //Check if item has already been confirmed
              if (draft.orders[orderIndex].confirmed !== 1) {
                draft.orders[orderIndex].total =
                  draft.orders[orderIndex].total -
                  draft.orders[orderIndex].orderDetails[orderDetailIndex]
                    .unitPrice *
                    draft.orders[orderIndex].orderDetails[orderDetailIndex]
                      .quantity;
                quantityToRemove +=
                  draft.orders[orderIndex].orderDetails[orderDetailIndex]
                    .quantity;
                draft.orders[orderIndex].orderDetails[
                  orderDetailIndex
                ].quantity = 0;
              }
            }
          });
          return draft;
        });

        //Keep items already confirmed in total, 0 everything else
        draft?.availableItems.forEach((item, itemIndex) => {
          if (item.recId === itemRecId) {
            draft!.availableItems[itemIndex].totalOrdered =
              draft!.availableItems[itemIndex].totalOrdered - quantityToRemove;
          }
        });
      });
      setShouldUpate(true);
    },
    [setApprovalData, setShouldUpate]
  );

  const refreshGrid = useCallback(async () => {
    try {
      if (typeof approvalData !== "undefined") {
        //setIsUpdating(true);
        setIsFetching(true);
        if (role === 1 || role === 4) {
          const approvalViewResult = await new TeamClient(
            process.env.REACT_APP_API_BASE
          ).teamLeaderReviewOrdersGET(
            approvalData?.orderType,
            approvalData?.deliveryDate,
            ""
          );
          setApprovalData(approvalViewResult);
        } else {
          const approvalViewResult = await new ManagerClient(
            process.env.REACT_APP_API_BASE
          ).managerReviewOrdersGET(
            approvalData?.orderType,
            approvalData?.deliveryMode,
            approvalData?.deliveryDate
          );
          setApprovalData(approvalViewResult);
        }
        setIsFetching(false);
      }
    } catch (e: unknown) {
      const error = e as ApiException;
      console.log(error.message);
      //setIsUpdating(false);
      setError(JSON.parse(error.response).error);
    }
  }, [approvalData, role, setApprovalData]);

  return (
    <>
      <Row className={"g-4 review-orders"}>
        <Col lg={12} className={"gx-5"}>
          <h3>Review {params.orderType} orders</h3>
        </Col>
        {role === 2 && approvalData?.deliveryDate && (
          <Col sm={2} className={"gx-5 gy-3"}>
            <DeliveryModePicker
              selected={approvalData.deliveryMode}
              size="sm"
              setDeliveryMode={setDeliveryMode}
              defaultValue={approvalData.deliveryMode}
            />
          </Col>
        )}
        {approvalData?.deliveryDate && (
          <Col sm={3} className={"gx-5 gy-3"}>
            <DeliveryDatePicker
              deliveryDates={possibleDeilveryDates}
              currentValue={
                currentDeliveryDate?.actualValue ?? approvalData?.deliveryDate
              }
              setDeliveryDate={setDeliveryDate}
              size="sm"
            />
          </Col>
        )}
        {role === 4 && (
          <Col sm={3} className={"gx-5 gy-3"}>
            <RetailManagerPicker
              selected={currentRetailManager}
              setRetailManager={setRegionalManager}
              size="sm"
            />
          </Col>
        )}
        {approvalData?.weekText && (
          <Col sm={6} className={"gx-5 gy-3"}>
            <Form.Label column sm={12}>
              {approvalData?.weekText}
            </Form.Label>
          </Col>
        )}
        <Col xs={12}>
          {!isFetching && isLoaded ? (
            <SingleCard
              pageTitle={`Review orders`}
              fluid={true}
              noHeader={true}
            >
              <div className={"review-orders"}>
                <>
                  {approvalData?.availableItems && approvalData.orders && (
                    <>
                      <SummaryTable
                        availableItems={approvalData?.availableItems}
                        orders={approvalData?.orders}
                        ref={(ref) => setSummaryRef(ref)}
                        updateStockTake={updateStockTake}
                        updateIncomming={updateIncomming}
                        alignedGrids={
                          zeroRef && ordersRef
                            ? [zeroRef, ordersRef]
                            : undefined
                        }
                      />
                      <ZeroTable
                        availableItems={approvalData?.availableItems}
                        ref={(ref) => setZeroRef(ref)}
                        zeroValuesForItem={zeroOrders}
                        alignedGrids={ordersRef ? [ordersRef] : undefined}
                        orders={approvalData?.orders}
                      />
                      <OrderTable
                        availableItems={approvalData?.availableItems}
                        orders={approvalData?.orders}
                        ref={(ref) => setOrdersRef(ref)}
                        alignedGrids={
                          summaryRef && zeroRef
                            ? [summaryRef, zeroRef]
                            : undefined
                        }
                        updateOrder={updateOrder}
                      />
                    </>
                  )}
                </>
              </div>
            </SingleCard>
          ) : (
            <SingleCard
              pageTitle={`Review orders`}
              fluid={true}
              noHeader={true}
            >
              <CardPlaceholder />
            </SingleCard>
          )}
        </Col>
        <Col lg={12} className={"gx-5 d-flex flex-row-reverse"}>
          <Stack gap={3} direction="horizontal">
            <LinkContainer to={".."}>
              <Button type="button" variant="light">
                {"Cancel"}
              </Button>
            </LinkContainer>
            {role === UserRole.Manager && (
              <Button
                type="submit"
                disabled={confirming}
                onClick={() => confirmOrders()}
              >
                {confirming ? "Confirming" : "Confirm"}
              </Button>
            )}
          </Stack>
        </Col>
        <Col xs={12}>
          <SingleCard pageTitle={`Missing orders`} fluid={true}>
            <MissingOrders
              missingStores={approvalData?.missingStores ?? []}
              refreshGrid={refreshGrid}
              orderType={approvalData?.orderType!}
              orderDate={approvalData?.deliveryDate!}
              approvalData={approvalData}
            />
          </SingleCard>
        </Col>
      </Row>
      <Modal show={error?.length! > 0} onHide={() => setError(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setError(null)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showPackingError} onHide={() => setShowPackingError(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>Update not made. Picking for order has started.</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowPackingError(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
