import { Formik } from "formik";
import { useCallback, useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import { LinkContainer } from "react-router-bootstrap";
import { useParams } from "react-router-dom";
import {
  ApiException,
  FileParameter,
  FileuploadClient,
} from "../../../services/ApiClient";
import { CardPlaceholder } from "../../common/cardPlaceholder/CardPlaceholder";
import { SingleCard } from "../../layouts/SingleCard";
import "./StorePhoto.scss";

interface IStorePhotoProps {}

interface IFormValues {
  files: FileList | undefined;
}

export const StorePhoto = (props: IStorePhotoProps) => {
  const [error, setError] = useState<null | string>(null);
  //const [isLoaded, setIsLoaded] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  //const [isPosting, setIsFetching] = useState(false);
  const [successfullyUpdated, setSuccessfullyUpdated] = useState(false);
  //const [file, setFile] = useState<StoreDTO>();
  const params = useParams();

  const onSubmit = useCallback(async (formData: IFormValues) => {
    try {
      setIsUpdating(true);
      //console.log(window.location.pathname);
      let files: FileParameter[] = [];
      var splitted = window.location.pathname.split("/", 5);

      Array.from(formData.files!).forEach((file) => {
        const fileParam: FileParameter = {
          data: file,
          fileName: file.name,
        };
        files.push(fileParam);
      });

      await new FileuploadClient(
        process.env.REACT_APP_API_BASE
      ).uploadShiftPhoto(files, splitted[4], splitted[2]);

      setIsUpdating(false);
      setSuccessfullyUpdated(true);
    } catch (e: unknown) {
      const error = e as ApiException;
      console.log(error.message);
      setIsUpdating(false);
      if (formData.files!.length === 0) {
        setIsUpdating(false);
        setError("Please input a valid image file.");
      } else {
        setError(JSON.parse(error.response).error);
      }
    }
  }, []);

  return (
    <>
      {isUpdating ? (
        <CardPlaceholder />
      ) : (
        <SingleCard pageTitle="Upload photo">
          <div className={"store-photo"}>
            <Formik
              //validationSchema={schema}
              onSubmit={onSubmit}
              initialValues={{
                files: undefined,
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldValue,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Row className="">
                    <Form.Group className="mb-3">
                      <Form.Label>Upload shift photo </Form.Label>
                      <Form.Control
                        type="file"
                        multiple
                        onChange={(event) => {
                          const target = event.target as HTMLInputElement;
                          setFieldValue("files", target.files!);
                        }}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Stack gap={3} direction="horizontal">
                        <Button type="submit">{"Upload"}</Button>{" "}
                        <LinkContainer to={`/stores/${params.storeid}`}>
                          <Button type="button" variant="light">
                            {"Cancel"}
                          </Button>
                        </LinkContainer>
                      </Stack>
                    </Form.Group>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
          <Modal show={error?.length! > 0} onHide={() => setError(null)}>
            <Modal.Header closeButton>
              <Modal.Title>Error</Modal.Title>
            </Modal.Header>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setError(null)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={successfullyUpdated}
            onHide={() => setSuccessfullyUpdated(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Successfully updated</Modal.Title>
            </Modal.Header>
            <Modal.Body>Images successfully updated.</Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setSuccessfullyUpdated(false)}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </SingleCard>
      )}
    </>
  );
};
