import { Formik } from "formik";
import { useCallback, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import { useParams } from "react-router-dom";
import {
  ApiException,
  FileParameter,
  ManagerClient,
} from "../../../services/ApiClient";
import { SingleCard } from "../../layouts/SingleCard";
import "./UploadBudgets.scss";

interface IUploadBudgetsProps {}

export const UploadBudgets = (props: IUploadBudgetsProps) => {
  const params = useParams();
  const [error, setError] = useState<null | string>(null);
  //const [isLoaded, setIsLoaded] = useState(false);
  //const [isUpdating, setIsUpdating] = useState(false);
  const [successfullyUpdated, setSuccessfullyUpdated] = useState(false);

  const updateSettings = useCallback(
    async (uploadData: any) => {
      try {
        //setIsUpdating(true);

        const fileParam: FileParameter = {
          data: uploadData.file,
          fileName: uploadData.file.name,
        };

        if (params.budgettype === "pots") {
          await new ManagerClient(
            process.env.REACT_APP_API_BASE
          ).uploadPotsBudgets(fileParam);
        } else if (params.budgettype === "cut") {
          await new ManagerClient(
            process.env.REACT_APP_API_BASE
          ).uploadCutsBudgets(fileParam);
        } else if (params.budgettype === "materials") {
          await new ManagerClient(
            process.env.REACT_APP_API_BASE
          ).uploadMaterialsBudgets(fileParam);
        }
        //setIsUpdating(false);
        setSuccessfullyUpdated(true);
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        //setIsUpdating(false);
        setError(JSON.parse(error.response).error);
      }
    },
    [params.budgettype]
  );

  return (
    <SingleCard pageTitle={`Upload ${params.budgettype} budgets`}>
      <div className={"upload-budgets"}>
        <Formik
          //validationSchema={schema}
          onSubmit={updateSettings}
          initialValues={{}}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            setFieldValue,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="">
                <Form.Group className="mb-3">
                  <Form.Label>Budget overrides CSV file</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(event) => {
                      const target = event.target as HTMLInputElement;
                      setFieldValue("file", target.files![0]);
                    }}
                  />
                </Form.Group>
                <Form.Group>
                  <Stack gap={3} direction="horizontal">
                    <Button type="submit">{"Save"}</Button>{" "}
                    <Button type="button" variant="light">
                      {"Cancel"}
                    </Button>
                  </Stack>
                </Form.Group>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
      <Modal show={error?.length! > 0} onHide={() => setError(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setError(null)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={successfullyUpdated}
        onHide={() => setSuccessfullyUpdated(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Successfully updated</Modal.Title>
        </Modal.Header>
        <Modal.Body>Budgets were successfully updated.</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setSuccessfullyUpdated(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </SingleCard>
  );
};
