import dayjs from "dayjs";
import { useCallback, useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Stack from "react-bootstrap/Stack";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import { RoleContext } from "../../../App";
import {
  ApiException,
  OrderClient,
  OrderDetailDTO,
  OrderType,
  UserRole,
} from "../../../services/ApiClient";
import { CardPlaceholder } from "../../common/cardPlaceholder/CardPlaceholder";
import { OrderDetails } from "../../common/orderDetails/OrderDetails";
import { NoCards } from "../../layouts/NoCards";
import { BudgetSummary } from "../newOrders/BudgetSummary";
import "./CurrentOrders.scss";

interface INewOrderProps {}

export const ViewOrderDetail = (props: INewOrderProps) => {
  const params = useParams();
  const [error, setError] = useState<null | string>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [orderData, setOrderData] = useImmer<OrderDetailDTO[] | null>(null);
  const [orderID, setorderID] = useState(0);
  const [storeName, setStoreName] = useImmer<string | null>(null);
  const [orderDate, setOrderDate] = useImmer<string | null>(null);
  const [orderType, setOrderType] = useImmer<string | null>(null);
  const [weekToDate, setWeekToDate] = useImmer<number>(0);
  const [monthToDate, setMonthToDate] = useImmer<number>(0);
  const [orderTotal, setOrderTotal] = useImmer<number>(0);
  const [budget, setBudget] = useImmer<number>(0);
  const [confirmed, setConfirmed] = useState<boolean>(true);
  const [hoursSinceOrder, setHoursSinceOrder] = useState<number>(0);
  const [boxesPacked, setboxesPacked] = useState<number | undefined>(undefined);
  const navigate = useNavigate();

  //const [checkboxValues, setCheckboxValues] = useState<boolean[]>([]); //confirm feature
  const [isButtonEnabled, setButtonEnabled] = useState(false); //confirm feature
  const role = useContext(RoleContext);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        setIsFetching(true);

        const storeid = params.storeid;
        const orderid = parseInt(params.orderid!);

        if (storeid && orderid) {
          const order = await new OrderClient(
            process.env.REACT_APP_API_BASE
          ).getOrder(orderid);

          const orderDateFromatted = order!.deliveryDate
            .substring(0, 10)
            .replaceAll("-", "/");
          const hoursSinceOrder = dayjs(dayjs()).diff(
            orderDateFromatted,
            "hours"
          );

          if (order) {
            setorderID(order.orderId);
            setOrderData(order.orderDetails!);
            setStoreName(order.store?.name ?? "");
            setOrderDate(orderDateFromatted);
            setOrderType(OrderType[order.orderType]);
            setWeekToDate(order.store?.totalWeekToDate ?? 0);
            setMonthToDate(order.store?.totalMonth ?? 0);
            setOrderTotal(order.total);
            setBudget(order.store?.budget ?? 0);
            setConfirmed(order.confirmed > 0);
            setHoursSinceOrder(hoursSinceOrder);
            setboxesPacked(order.packedBox);
          }
        }
        setIsLoaded(true);
        setIsFetching(false);
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        setIsFetching(false);
        setError(JSON.parse(error.response).error);
      }
    };

    fetchOrder().catch(console.error);
  }, [
    params.orderid,
    params.storeid,
    setBudget,
    setMonthToDate,
    setOrderData,
    setOrderDate,
    setOrderTotal,
    setOrderType,
    setStoreName,
    setWeekToDate,
  ]);

  const updateButtonEnabled = useCallback(() => {
    if (orderData) {
      const initialButtonEnabledValues = orderData.map((orderItem) => {
        const hasCreditRequest = !!orderItem.creditItem; // Check if CreditRequestDTO exists
        const isReceived = orderItem.received; // Check received status
        const isDeleted = orderItem.deleted; // Check if item is deleted

        return hasCreditRequest || isReceived || isDeleted;
      });

      //setCheckboxValues(initialButtonEnabledValues);

      setButtonEnabled(
        initialButtonEnabledValues.every((value) => value === true)
      );
    }
  }, [orderData]);

  useEffect(() => {
    updateButtonEnabled();
  }, [orderData, updateButtonEnabled]);

  const updateRecieved = async (
    currentOrderID: number,
    currentOrderDetailID: number,
    value: boolean
  ) => {
    const update = await new OrderClient().receivedOrderDetail(
      currentOrderID,
      currentOrderDetailID,
      value
    );

    setOrderData(update.orderDetails);
  };

  const handleConfirmOrder = async () => {
    //confirm feature

    const update = await new OrderClient().receivedOrder(orderID, true);
    setOrderData(update.orderDetails);

    navigate(`/stores/${params.storeid}/orders`);
  };

  const handleSelectAll = () => {
    const allCheckboxValues = orderData?.map(() => true) || [];

    //setCheckboxValues(allCheckboxValues);

    setButtonEnabled(
      allCheckboxValues.length > 0 && allCheckboxValues.every((value) => value)
    );

    if (orderData) {
      for (const orderDetail of orderData) {
        updateRecieved(orderID, orderDetail.orderDetailId, true);
      }
    }
  };

  return (
    <>
      <NoCards
        pageTitle={storeName ? `Order summary - ${storeName}` : undefined}
        className={"current-orders"}
      >
        {!isFetching && isLoaded ? (
          <>
            <Col lg={12}>
              <Stack gap={3} direction="vertical">
                <h5>
                  {orderType} {dayjs(orderDate).format("DD/MM/YYYY")}
                </h5>
              </Stack>
            </Col>
            <Col lg={12}>
              <Stack gap={3} direction="horizontal">
                {boxesPacked && <div>Boxes packed: {boxesPacked}</div>}
                <div className="ms-auto">
                  <Button onClick={() => handleSelectAll()}>Select All</Button>
                </div>
              </Stack>
            </Col>

            {typeof orderData !== "undefined" && orderData ? (
              <>
                <Col lg={12}>
                  <OrderDetails
                    orderData={orderData}
                    orderTotal={orderTotal!}
                    updateRecieved={updateRecieved}
                    setOrderData={setOrderData}
                    newOrder={false}
                    showCredit={
                      hoursSinceOrder < 48 &&
                      confirmed &&
                      role?.role !== UserRole.InStoreStaff
                    }
                  />
                </Col>
                <BudgetSummary
                  orderTotal={orderTotal}
                  wtd={weekToDate}
                  mtd={monthToDate}
                  budget={budget}
                  orderType={orderType ?? ""}
                  hideTitle={false}
                />
              </>
            ) : (
              <Col lg={12}>
                <Card className="shadow-sm p-0" border="light">
                  <Card.Body className="p-0 available-products-card">
                    <div className="no-items">No orders available.</div>
                  </Card.Body>
                </Card>
              </Col>
            )}
            <Col lg={12} className={"d-flex flex-row-reverse"}>
              <Stack gap={3} direction="horizontal">
                <Button
                  disabled={!isButtonEnabled}
                  onClick={() => handleConfirmOrder()}
                >
                  Confirm Order
                </Button>
                <LinkContainer to={`/stores/${params.storeid}/orders`}>
                  <Button type="button" variant="light">
                    {"Back"}
                  </Button>
                </LinkContainer>
              </Stack>
            </Col>
          </>
        ) : (
          <Col xs={12} lg={12}>
            <Card className="shadow-sm p-0" border="light">
              <Card.Body className="p-0 available-products-card">
                <CardPlaceholder />
              </Card.Body>
            </Card>
          </Col>
        )}
      </NoCards>
      <Modal show={error?.length! > 0} onHide={() => setError(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setError(null)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
