import {
  ColDef,
  ICellRendererParams,
  RowStyle,
  ValueFormatterParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import React, { useCallback, useMemo } from "react";
import Button from "react-bootstrap/Button";
import { Updater } from "use-immer";
import { OrderDTO, OrderDetailDTO } from "../../../services/ApiClient";
import useWindowDimensions from "../../../ultilities/UseWIndowDimensions";
import { CheckboxRenderer } from "./CheckboxRenderer";
import { NumberInputRenderer } from "./NumberInputRenderer";
import "./OrderHeader.scss";
import { SelectAllHeader } from "./SelectAllHeader";

interface IPickOrderTableProps {
  order: OrderDetailDTO[];
  orderId: number;
  setOrdersData: Updater<OrderDTO | null>;
  //updatePickedStatus: (currentOrder: OrderDetailDTO) => void;
  gridRef: React.MutableRefObject<AgGridReact | null>;
  picking: boolean;
  packing: boolean;
  allSelected: boolean;
}

export const PickOrderTable = (props: IPickOrderTableProps) => {
  const { width } = useWindowDimensions();

  const totalValueFormatter = (params: ValueFormatterParams) => {
    const quantity = Number(params.data.quantity);
    const unitPrice = Number(params.data.unitPrice);
    const totalValue = quantity * unitPrice;

    return `$${totalValue.toFixed(2)}`;
  };

  const deleteRow = useCallback(
    async (data: OrderDetailDTO) => {
      //const order = await new OrderClient().deleteOrderDetail(data);
      props.setOrdersData((draft) => {
        const updateOrderItem = draft?.orderDetails.find(
          (orderDetail) => orderDetail.itemId === data.itemId
        );
        if (updateOrderItem) {
          updateOrderItem.deleted = data.deleted ? false : true;
          updateOrderItem.pickPackStatus = 0;
        }
      });
    },
    [props]
  );

  const updateDetail = useCallback(
    async (data: OrderDetailDTO) => {
      props.setOrdersData((draft) => {
        const updateOrderItem = draft?.orderDetails.find(
          (orderDetail) => orderDetail.itemId === data.itemId //&&
          //orderDetail.unitPrice === data.unitPrice
        );
        if (updateOrderItem) {
          updateOrderItem.quantity = data.quantity;
          updateOrderItem.unitPrice = data.unitPrice;
          updateOrderItem.total = data.quantity * data.unitPrice;
          updateOrderItem.pickPackStatus = data.pickPackStatus;
        }
      });
    },
    [props]
  );

  const selectAll = useCallback(
    async (pickPackStatus: number, selected: boolean) => {
      const pickPackStatusToSet = selected
        ? pickPackStatus
        : pickPackStatus - 1;

      props.setOrdersData((draft) => {
        draft?.orderDetails.forEach((orderDetail) => {
          if (!orderDetail.deleted) {
            orderDetail.pickPackStatus = pickPackStatusToSet;
          }
        });
      });
    },
    [props]
  );

  const DeleteRenderer = useCallback(
    (props: ICellRendererParams): JSX.Element => {
      const variant = props.data.deleted ? "outline-success" : "primary";

      return (
        <div
          className={"btn-container d-flex align-items-center"}
          style={{ height: "100%" }}
        >
          <Button
            size="sm"
            variant={variant}
            onClick={() => deleteRow(props.data)}
          >
            {props.data.deleted ? "Undo" : "Delete"}
          </Button>
        </div>
      );
    },
    [deleteRow]
  );

  /*const columnTypes = useMemo<{
    [key: string]: ColDef;
  }>(() => {
    return {
      numberColumn: { width: 130, filter: "agNumberColumnFilter" },
    };
  }, []);
  */

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "Product",
        field: "item.itemName",
        editable: false,
        minWidth: width > 768 ? 308 : 160,
        maxWidth: width > 768 ? 308 : 160,
        multiLine: true,
        pinned: "left",
      },
      {
        headerName: "Comment",
        field: "comment",
        editable: false,
        minWidth: 240,
        maxWidth: 240,
        multiLine: true,
      },
      {
        headerName: "Qty",
        field: "quantity",
        type: "numberColumn",
        cellRenderer: NumberInputRenderer,
        cellRendererParams: {
          updateNumber: updateDetail,
          pickPackStatus: props.packing ? 2 : 1,
        },
        editable: false,
        minWidth: 140,
        maxWidth: 140,
        //editable: (params: EditableCallbackParams | CellClassParams) => !params.data.deleted && params.data.pickPackStatus !== 1
      },
      {
        headerName: "Price",
        field: "unitPrice",
        type: "numberColumn",
        cellRenderer: NumberInputRenderer,
        cellRendererParams: {
          updateNumber: updateDetail,
          pickPackStatus: props.packing ? 2 : 1,
          showAsPrice: true,
        },
        editable: false,
        minWidth: 140,
        maxWidth: 140,
        //editable: (params: EditableCallbackParams | CellClassParams) => !params.data.deleted && params.data.pickPackStatus !== 1
      },
      {
        headerName: "Total Value",
        field: "total",
        flex: 2,
        type: "numberColumn",
        valueFormatter: totalValueFormatter,
        editable: false,
        minWidth: 120,
      },
      ...(props.picking
        ? [
            {
              headerName: "Picked",
              headerComponent: SelectAllHeader,
              headerComponentParams: {
                label: "Picked",
                selectAll: selectAll,
                checked: props.allSelected,
                pickPackStatus: props.packing ? 2 : 1,
              },
              headerClass: "fd-text-center",
              field: "pickPackStatus",
              flex: 2,
              cellRenderer: CheckboxRenderer,
              cellRendererParams: {
                updatePickedStatus: updateDetail,
                pickPackStatus: 1,
              },
              minWidth: 140,
            },
          ]
        : []),
      ...(props.packing
        ? [
            {
              headerName: "Packed",
              headerComponent: SelectAllHeader,
              headerComponentParams: {
                label: "Packed",
                selectAll: selectAll,
                checked: props.allSelected,
                pickPackStatus: props.packing ? 2 : 1,
              },
              headerClass: "fd-text-center",
              field: "pickPackStatus",
              flex: 2,
              cellRenderer: CheckboxRenderer,
              cellRendererParams: {
                updatePickedStatus: updateDetail,
                pickPackStatus: 2,
              },
              minWidth: 140,
            },
          ]
        : []),
      {
        headerName: "",
        field: "",
        flex: 2,
        cellRenderer: DeleteRenderer,
        minWidth: 100,
      },
    ],
    [
      DeleteRenderer,
      props.allSelected,
      props.packing,
      props.picking,
      selectAll,
      updateDetail,
      width,
    ]
  );

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      suppressMovable: true,
    };
  }, []);

  const getRowStyle = (params: any): RowStyle => {
    if (params.data.deleted) {
      return { opacity: 0.5 };
    }
    return {};
  };

  return (
    <div className="ag-theme-alpine ag-row-pointer available-products-container">
      <AgGridReact
        ref={props.gridRef}
        className={`ag-theme-alpine`}
        columnDefs={columnDefs}
        //columnTypes={columnTypes}
        domLayout="autoHeight"
        rowData={props.order}
        singleClickEdit={true}
        getRowStyle={getRowStyle}
        alwaysShowHorizontalScroll={true}
        defaultColDef={defaultColDef}
      />
    </div>
  );
};
