import dayjs from "dayjs";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./UnprocessedOrders.scss";

interface IDeleteConfirmModalProps {
  orderId: number;
  show: boolean;
  deleteItem: (orderId: number) => void;
  handleClose: () => void;
  storeName: string;
  date: string;
  orderAmount: number;
}

export const DeleteConfirmModal = (props: IDeleteConfirmModalProps) => {
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirm delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {`Are you sure you want to delete your ${
          props.storeName
        } order on ${dayjs(props.date).format("DD/MM/YYYY")}`}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Cancel
        </Button>
        <Button
          onClick={() => props.deleteItem(props.orderId)}
          variant="primary"
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
