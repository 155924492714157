import React from "react";
import { ReactComponent as EditIndicatorIcon } from "../../../assets/check-circle-fill.svg";
import { ICellRendererParams } from "ag-grid-community";

export const EditIndicatorCellRenderer = (
  props: ICellRendererParams,
): JSX.Element => {
  const className =
    props.colDef?.field === "teamLeadReviewed"
      ? "edit-icon-blue"
      : "edit-icon-green";

  return (
    <>
      {props.value && (
        <div className={"icon-container  " + className}>
          <EditIndicatorIcon />
        </div>
      )}
    </>
  );
};
