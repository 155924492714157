import { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { useNavigate, useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import {
  ApiException,
  ManagerClient,
  StoreTypeBudgetMultiplier,
} from "../../../services/ApiClient";
import { CardPlaceholder } from "../../common/cardPlaceholder/CardPlaceholder";
import { NoCards } from "../../layouts/NoCards";
import { BudgetMultiplierForm } from "./BudgetMultiplierForm";
import "./PortalSettings.scss";

interface IBudgetMultiplierEditProps {}

export const BudgetMultiplierEdit = (props: IBudgetMultiplierEditProps) => {
  const params = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState<null | string>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [multiplierData, setMultiplierData] =
    useImmer<StoreTypeBudgetMultiplier | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsFetching(true);
        const multiplierId = parseInt(params?.multiplierId!);
        const currentMultiplier = await new ManagerClient(
          process.env.REACT_APP_API_BASE
        ).getStoreTypeBudgetMultiplier(multiplierId);

        if (typeof currentMultiplier !== "undefined") {
          setMultiplierData(currentMultiplier!);
          setIsLoaded(true);
          setIsFetching(false);
        } else {
          setError("Multiplier not found.");
        }
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        setIsFetching(false);
        setError(JSON.parse(error.response).error);
      }
    };

    fetchData().catch(console.error);
  }, [params, setMultiplierData]);

  //Updates all availability data inluding store types that exists on the top level DTO.
  const saveItem = useCallback(
    async (multiplierData: StoreTypeBudgetMultiplier) => {
      try {
        setIsSaving(true);

        await new ManagerClient(
          process.env.REACT_APP_API_BASE
        ).updateStoreTypeBudgetMultiplier(multiplierData);

        setIsSaving(false);
        navigate("/settings");
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        setError(JSON.parse(error.response).error);
      }
    },
    [navigate]
  );

  //Updates all availability data inluding store types that exists on the top level DTO.
  const deleteItem = useCallback(
    async (multiplierData: StoreTypeBudgetMultiplier) => {
      try {
        setIsSaving(true);

        await new ManagerClient(
          process.env.REACT_APP_API_BASE
        ).deleteStoreTypeBudgetMultiplier(multiplierData.id);

        setIsSaving(false);
        navigate("/settings");
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        setError(JSON.parse(error.response).error);
      }
    },
    [navigate]
  );

  return (
    <div className={"portal-settings"}>
      <NoCards pageTitle="">
        {!isFetching && isLoaded ? (
          <>
            <Col md={12}>
              <h3>Edit multiplier</h3>
            </Col>
            <Col lg={12}>
              <Card className="shadow-sm p-0" border="light">
                <Card.Body className="p-0 portal-settings-form">
                  {multiplierData && (
                    <BudgetMultiplierForm
                      budgetMultiplier={multiplierData}
                      updateBudgetMultiplier={saveItem}
                      deleteMultiplier={deleteItem}
                      isNew={false}
                      isSaving={isSaving}
                    />
                  )}
                </Card.Body>
              </Card>
            </Col>
          </>
        ) : (
          <CardPlaceholder />
        )}
      </NoCards>
      <Modal show={error?.length! > 0} onHide={() => setError(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setError(null)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
