import React, { useState, useMemo, useCallback } from "react";
import Form from "react-bootstrap/Form";
import useDebounce from "../../../ultilities/UseDebounce";
import { AgGridReact } from "ag-grid-react";
import { ColDef, ColGroupDef, RowClickedEvent } from "ag-grid-community";

import "./Picker.scss";

interface IPickerProps {
  columnDefs: (ColDef | ColGroupDef)[] | null;
  rowData: any[];
  onRowSelected(selectedRows: any): void;
}

export const Picker = (props: IPickerProps) => {
  //const gridRef = useRef<AgGridReact<any>>(null);
  //const [rowData, setRowData] = useState<any[]>(props.rowData);
  const [rowData] = useState<any[]>(props.rowData);
  const [filteredRowData, setRowFilteredData] = useState<any[]>(props.rowData);
  const [searchTerm, setSearchTerm] = useState("");
  const pageSize = 100;

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
    }),
    [],
  );

  // Debounce to avoid re-renders on search
  useDebounce(
    () => {
      if (searchTerm.length > 0) {
        setRowFilteredData(
          rowData.filter((d) =>
            (d.name as string).toLowerCase().includes(searchTerm.toLowerCase()),
          ),
        );
      } else {
        setRowFilteredData(rowData);
      }
    },
    [rowData, searchTerm],
    300,
  );

  const handleSearch = useCallback(
    (e: any) => {
      setSearchTerm(e.target.value);
    },
    [setSearchTerm],
  );

  const onRowClicked = useCallback(
    (event: RowClickedEvent) => {
      props.onRowSelected(event.data);
    },
    [props],
  );

  return (
    <>
      <div className="picker-search">
        <Form.Control
          type="search"
          placeholder="Search"
          className="me-2"
          aria-label="Search"
          onChange={handleSearch}
        />
      </div>
      <div className="ag-theme-alpine ag-no-header ag-row-pointer picker-table">
        <AgGridReact
          columnDefs={props.columnDefs}
          rowData={filteredRowData}
          defaultColDef={defaultColDef}
          domLayout="autoHeight"
          onRowClicked={onRowClicked}
          rowSelection={"single"}
          pagination={rowData.length > pageSize}
          paginationPageSize={pageSize}
        />
      </div>
    </>
  );
};
