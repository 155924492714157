import { ICellRendererParams } from "ag-grid-community";
import React, { useCallback } from "react";
import { OrderDetailDTO } from "../../../services/ApiClient";

interface ICheckBoxRendererProps {
  updatePickedStatus: (currentOrder: OrderDetailDTO) => void;
  pickPackStatus: number;
}

export const CheckboxRenderer = (
  props: ICellRendererParams & ICheckBoxRendererProps
) => {
  const onClick = useCallback(
    (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
      const data = props.data as OrderDetailDTO;
      if (event.currentTarget.checked) {
        const updatedData: OrderDetailDTO = {
          ...data,
          pickPackStatus: props.pickPackStatus,
        };
        props.updatePickedStatus(updatedData);
      } else {
        const updatedData: OrderDetailDTO = {
          ...data,
          pickPackStatus: props.pickPackStatus - 1,
        };
        props.updatePickedStatus(updatedData);
      }
    },
    [props]
  );

  return (
    <div className="form-check fd-checkbox-container">
      <input
        className="form-check-input fd-checkbox-lg"
        role="switch"
        type="checkbox"
        onClick={onClick}
        defaultChecked={props.value === props.pickPackStatus}
        disabled={props.data.deleted}
      />
    </div>
  );
};
