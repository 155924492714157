import { ChangeEvent, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
/*import Button from 'react-bootstrap/Button';*/
import dayjs from "dayjs";
import Modal from "react-bootstrap/Modal";
import { useNavigate, useParams } from "react-router-dom";
import {
  CreditRequestDTO,
  CreditrequestStatus,
  FileParameter,
  FileuploadClient,
  OrderClient,
  OrderDetailDTO,
} from "../../../services/ApiClient";
//import { Field, Form, Formik } from 'formik';
import { ErrorMessage, Field, Formik } from "formik";
import { Button, Form, FormLabel } from "react-bootstrap";

interface ICreateCreditRequestProps {
  orderDetailItem: OrderDetailDTO;
  setOrderData: (currentOrder: OrderDetailDTO[] | null) => void;
  setOrderDetailItem: (orderDetail: OrderDetailDTO | null) => void;
}

type FormValues = Omit<CreditRequestDTO, "quantity" | "price"> & {
  quantity: number | undefined;
  price: number | undefined;
};

export const creditReasons = [
  "Product Damaged in Transit",
  "Incorrect Price",
  "Incorrect Quantity",
  "Delivery Not Received",
  "Product Not Received",
  "Bad Quality Product",
];

export const CreateCreditRequest = (props: ICreateCreditRequestProps) => {
  const [creditRequest, setCreditRequest] = useState<FormValues>({
    creditrequestid: 0,
    quantity: undefined,
    price: undefined,
    reason: creditReasons[0],
    comment: "",
    photo: "",
    orderid: props.orderDetailItem.orderId,
    orderNumber: "",
    orderdetailid: props.orderDetailItem.orderDetailId,
    status: CreditrequestStatus.New,
    posted: 0,
    rejectReason: "",
    productName: props.orderDetailItem.item?.itemName ?? "",
    requestedBy: "",
    requestedDate: dayjs().format("YYYY-MM-DD"),
    deliveryDate: dayjs().format("YYYY-MM-DD"),
    originalPrice: props.orderDetailItem.item?.price ?? 0,
  });
  const [markedDown, setMarkedDown] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const navigate = useNavigate();
  const { storeid } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const uploadPhoto = async (
    files: FileList,
    creditRequestID: number,
    orderID: number
  ) => {
    let filesParams: FileParameter[] = [];

    Array.from(files!).forEach((file) => {
      const fileParam: FileParameter = {
        data: file,
        fileName: file.name,
      };
      filesParams.push(fileParam);
    });

    await new FileuploadClient().uploadCRphoto(
      filesParams,
      creditRequestID,
      orderID
    );
  };

  const handleSubmit = async (values: FormValues) => {
    const updatedValues: CreditRequestDTO = {
      ...values,
      price: values.price ?? 0,
      quantity: values.quantity ?? 0,
    };

    setSubmitting(true);

    const result = await new OrderClient(
      process.env.REACT_APP_API_BASE
    ).requestCredit(updatedValues);

    if (selectedFiles) {
      await uploadPhoto(selectedFiles, result.creditrequestid, result.orderid);
    }

    navigate(`/stores/${storeid}`);
  };

  const handleReset = async () => {
    setIsLoading(true);

    await setCreditRequest({
      creditrequestid: 0,
      quantity: undefined,
      price: undefined,
      reason: "",
      comment: "",
      photo: "",
      orderid: props.orderDetailItem.orderId,
      orderNumber: "",
      orderdetailid: props.orderDetailItem.orderDetailId,
      status: CreditrequestStatus.New,
      posted: 0,
      rejectReason: "",
      productName: props.orderDetailItem.item?.itemName ?? "",
      requestedBy: "",
      requestedDate: "",
      deliveryDate: "",
      originalPrice: props.orderDetailItem.item?.price ?? 0,
    });

    setIsLoading(false);
  };

  return (
    <Modal
      show={props.orderDetailItem !== null}
      onHide={() => props.setOrderDetailItem(null)}
      backdrop="static"
      keyboard={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Create Credit Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          <Formik
            initialValues={creditRequest}
            onSubmit={handleSubmit}
            validate={(values) => {
              const errors: Partial<Record<keyof CreditRequestDTO, string>> =
                {};

              console.log("In validate :" + values);

              if (values.quantity === undefined || values.quantity === 0) {
                errors.quantity = "Quantity must be greater than zero.";
              }

              if (
                (markedDown === true && typeof values.price === "undefined") ||
                values.price === 0
              ) {
                errors.price = "Price must be greater than zero.";
              }

              if (values.quantity! > props.orderDetailItem.quantity) {
                errors.quantity = "Quantity higher than amount ordered.";
              }

              if (
                markedDown === true &&
                values.price! >= (props.orderDetailItem.item?.price ?? 0)
              ) {
                errors.price =
                  "Price must be less than the original item price";
              }

              if (values.reason === "Other" && !values.comment) {
                errors.comment = 'Comment is required for "Other" reason';
              }

              return errors;
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              dirty,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row className="mb-2">
                  <Col>
                    <FormLabel htmlFor="productName">Product Name</FormLabel>
                    <Field
                      type="text"
                      id="productName"
                      name="productName"
                      className="form-control"
                      disabled
                    />
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col>
                    <FormLabel htmlFor="markedDown">
                      Product Marked Down
                    </FormLabel>
                  </Col>
                  <Col>
                    <Field
                      type="checkbox"
                      id="markedDown"
                      name="markedDown"
                      className="form-check-input"
                      checked={markedDown}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setMarkedDown(e.target.checked)
                      }
                    />
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col>
                    <FormLabel htmlFor="quantity">Quantity</FormLabel>
                    <Field
                      type="number"
                      id="quantity"
                      name="quantity"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="quantity"
                      component="div"
                      className="text-danger"
                    />
                  </Col>
                  {markedDown && (
                    <Col>
                      <FormLabel htmlFor="price">
                        Price product marked down to
                      </FormLabel>
                      <Field
                        type="number"
                        id="price"
                        name="price"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="price"
                        component="div"
                        className="text-danger"
                      />
                    </Col>
                  )}
                </Row>

                <Row className="mb-2">
                  <Col>
                    <FormLabel htmlFor="reason">Reason</FormLabel>
                    <Form.Select
                      onChange={handleChange}
                      id={"reason"}
                      name={"reason"}
                      value={values.reason}
                    >
                      {creditReasons.map((reason) => (
                        <option key={reason} value={reason}>
                          {reason}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col>
                    <FormLabel htmlFor="comment">Comment</FormLabel>
                    <Field
                      as="textarea"
                      id="comment"
                      name="comment"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="comment"
                      component="div"
                      className="text-danger"
                    />
                  </Col>
                </Row>

                <Row className="">
                  <Col>
                    <FormLabel htmlFor="photo">Upload Photo</FormLabel>
                    <div>
                      <input
                        id="file"
                        name="file"
                        type="file"
                        multiple
                        onChange={(event) => {
                          if (event.target.files) {
                            setSelectedFiles(event.target.files);
                          }
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col lg={12} className={"d-flex justify-content-end gap-2"}>
                    <Button
                      type="reset"
                      variant="secondary"
                      onClick={handleReset}
                    >
                      Reset
                    </Button>
                    <Button
                      type="submit"
                      disabled={!isValid || isSubmitting}
                      className="mr-2"
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        )}
      </Modal.Body>
    </Modal>
  );
};
