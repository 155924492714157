import dayjs from "dayjs";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { DeliveryDateDTO } from "../../../services/ApiClient";
import { DeliveryDatePicker } from "../../common/deliveryDatePicker/DeliveryDatePicker";
import "./UnprocessedOrders.scss";

interface IUpdateDateConfirmModalProps {
  orderId: number;
  show: boolean;
  updateOrderDate: (orderId: number, deliveryDate: string) => void;
  handleClose: () => void;
  storeName: string;
  date: string;
  possibleDelieryDates: DeliveryDateDTO[];
  orderAmount: number;
}

export const UpdateDateConfirmModal = (props: IUpdateDateConfirmModalProps) => {
  const [deliveryDate, setDeliveryDate] = useState<DeliveryDateDTO | null>(
    null
  );

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Update delivery date</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3" controlId="formGroupEmail">
          <Form.Label>{`Update delivery date for your ${
            props.storeName
          } order on ${dayjs(props.date).format("DD/MM/YYYY")}`}</Form.Label>
          <DeliveryDatePicker
            deliveryDates={props.possibleDelieryDates}
            currentValue={deliveryDate?.actualValue! ?? props.date}
            setDeliveryDate={setDeliveryDate}
            size="sm"
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Cancel
        </Button>
        <Button
          onClick={() =>
            props.updateOrderDate(props.orderId, deliveryDate?.actualValue!)
          }
          variant="primary"
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
