import { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import {
  ApiException,
  ItemAvailabilityMatrixClient,
  ItemAvailabilityViewDTO,
  KooItemavailabilitytable,
} from "../../../services/ApiClient";
import { CardPlaceholder } from "../../common/cardPlaceholder/CardPlaceholder";
import { NoCards } from "../../layouts/NoCards";
import "./ProductAvailability.scss";
import { ProductAvailabilityForm } from "./ProductAvailabilityForm";
import { ProductAvailabilityTable } from "./ProductAvailabilityTable";

interface IProductAvailabilityProps {}

export const ProductAvailability = (props: IProductAvailabilityProps) => {
  const params = useParams();
  const [error, setError] = useState<null | string>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [availabilityData, setAvailabilityData] =
    useImmer<ItemAvailabilityViewDTO | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsFetching(true);
        //0 will get availability for all products, passing in the product redId will get a single product
        const recId = params?.recId ? parseInt(params?.recId!) : 0;
        const result = await new ItemAvailabilityMatrixClient(
          process.env.REACT_APP_API_BASE
        ).getItemAvailability(recId);

        setAvailabilityData(result);
        setIsLoaded(true);
        setIsFetching(false);
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        setIsFetching(false);
        setError(JSON.parse(error.response).error);
      }
    };

    fetchData().catch(console.error);
  }, [params, setAvailabilityData]);

  const updateItemAvailabilityState = useCallback(
    (availabilityItem: KooItemavailabilitytable) => {
      setAvailabilityData((draft) => {
        const index = draft?.itemAvailabilities?.findIndex(
          (itemAvailablity: KooItemavailabilitytable) =>
            availabilityItem.recId === itemAvailablity.recId
        );
        //Replace our existing item with our newly updated one
        if (index !== -1) {
          draft!.itemAvailabilities![index!] = availabilityItem;
          if (!availabilityItem.deleted) {
            draft!.itemAvailability = availabilityItem;
          }
        }
      });
    },
    [setAvailabilityData]
  );

  //Updates all availability data inluding store types that exists on the top level DTO.
  const saveAvailabilityItem = useCallback(
    async (availabilityData: ItemAvailabilityViewDTO) => {
      if (availabilityData?.itemAvailability) {
        try {
          setIsSaving(true);

          //TO DO FIX THIS UP NICELY
          availabilityData.itemAvailability.itemName =
            availabilityData.products?.find(
              (product) =>
                product.itemId === availabilityData.itemAvailability?.itemId
            )?.itemName ?? "";

          await new ItemAvailabilityMatrixClient(
            process.env.REACT_APP_API_BASE
          ).saveItemAvailability(availabilityData!);

          updateItemAvailabilityState(availabilityData?.itemAvailability);
          setIsSaving(false);
        } catch (e: unknown) {
          const error = e as ApiException;
          console.log(error.message);
          setError(JSON.parse(error.response).error);
        }
      }
    },
    [updateItemAvailabilityState]
  );

  const saveAvailabilityTable = useCallback(
    async (itemavailabilitytable: KooItemavailabilitytable) => {
      const availabilityData: ItemAvailabilityViewDTO = {
        itemAvailability: itemavailabilitytable,
      };
      await saveAvailabilityItem(availabilityData);
    },
    [saveAvailabilityItem]
  );

  const deleteItemAvailabilityState = useCallback(
    async (availabilityRecId: number) => {
      setAvailabilityData((draft) => {
        const index = draft?.itemAvailabilities?.findIndex(
          (itemAvailablity: KooItemavailabilitytable) =>
            availabilityRecId === itemAvailablity.recId
        );
        if (index !== -1) draft!.itemAvailabilities?.splice(index!, 1);
      });
    },
    [setAvailabilityData]
  );

  const deleteAvailabilityItem = useCallback(
    async (itemavailabilitytable: KooItemavailabilitytable) => {
      try {
        setIsSaving(true);
        //We won't use this as its a hard delete, we will set a delete flag on the item instead
        //const result = await new ItemAvailabilityMatrixClient(process.env.REACT_APP_API_BASE).deleteItemAvailability(availabilityRecId);
        const availabilityTableClone = { ...itemavailabilitytable };
        availabilityTableClone.deleted = true;
        await saveAvailabilityTable(availabilityTableClone);
        deleteItemAvailabilityState(itemavailabilitytable.recId);
        setIsSaving(false);
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        setError(JSON.parse(error.response).error);
      }
    },
    [deleteItemAvailabilityState, saveAvailabilityTable]
  );

  return (
    <div className={"portal-settings"}>
      <NoCards pageTitle="">
        {!isFetching && isLoaded ? (
          <>
            <Col lg={12}>
              <ProductAvailabilityTable
                availabilityTable={availabilityData?.itemAvailabilities!}
                updateAvailabilityItem={saveAvailabilityTable}
                deleteAvailabilityItem={deleteAvailabilityItem}
              />
            </Col>
            <Col lg={12}>
              <Card className="shadow-sm p-0" border="light">
                <Card.Body className="p-0 portal-settings-form">
                  {typeof availabilityData?.itemAvailability !== "undefined" &&
                    availabilityData?.itemAvailability && (
                      <ProductAvailabilityForm
                        avilabilityData={availabilityData!}
                        updateAvailabilityData={saveAvailabilityItem}
                        isNew={false}
                        isSaving={isSaving}
                      />
                    )}
                </Card.Body>
              </Card>
            </Col>
          </>
        ) : (
          <CardPlaceholder />
        )}
      </NoCards>
      <Modal show={error?.length! > 0} onHide={() => setError(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setError(null)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
