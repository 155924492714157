import { RouteGuard } from "./components/common/routeGuard/RouteGuard";
import { CreditRequestDetails } from "./components/pages/creditRequests/CreditDetails";
import { ReviewCreditRequests } from "./components/pages/creditRequests/CreditRequests";
import { CurrentOrders } from "./components/pages/currentOrders/CurrentOrders";
import { ViewOrderDetail } from "./components/pages/currentOrders/ViewOrderDetail";
import { Home } from "./components/pages/home/Home";
import { ViewMerchandiseCreditRequests } from "./components/pages/merchandiseCreditRequests/MerchandiseCreditRequests";
import { ViewMerchandiseCreditRequestsDetails } from "./components/pages/merchandiseCreditRequests/MerchandiseCreditRequestsDetails";
import { NewOrder } from "./components/pages/newOrders/NewOrder";
import { NotFound } from "./components/pages/notFound/NotFound";
import { OrderingHome } from "./components/pages/orderingHome/OrderingHome";
import { Orders } from "./components/pages/orders/Orders";
import { PickOrder } from "./components/pages/pickOrder/PickOrder";
import { NextOrder } from "./components/pages/pickOrders/NextOrder";
import { PickOrders } from "./components/pages/pickOrders/PickOrders";
import { BudgetMultiplierEdit } from "./components/pages/portalSettings/BudgetMultiplierEdit";
import { BudgetMultiplierNew } from "./components/pages/portalSettings/BudgetMultiplierNew";
import { PortalSettings } from "./components/pages/portalSettings/PortalSettings";
import { NewProductAvailability } from "./components/pages/productAvailability/NewProductAvailability";
import { ProductAvailability } from "./components/pages/productAvailability/ProductAvailability";
import { StoreKpis } from "./components/pages/storeKpis/StoreKpis";
import { StorePhoto } from "./components/pages/storePhoto/StorePhoto";
import { StorePicker } from "./components/pages/storePicker/StorePicker";
import { StoreSettings } from "./components/pages/storeSettings/StoreSettings";
import { StoreWastage } from "./components/pages/storeWastage/StoreWastage";
import { UnprocessedOrders } from "./components/pages/unprocessedOrders/UnprocessedOrders";
import { UploadBudgets } from "./components/pages/uploadBudgets/UploadBudgets";
import { UploadPreOrders } from "./components/pages/uploadPreOrders/UploadPreOrders";
import { UserEdit } from "./components/pages/userManagement/UserEdit";
import { UserNew } from "./components/pages/userManagement/UserNew";
import { Users } from "./components/pages/userManagement/Users";
import { NewTransfer } from "./components/pages/vanTransfers/NewTransfer";
import { UserRole } from "./services/ApiClient";

const AppRoutes = [
  {
    index: true,
    element: (
      <RouteGuard
        requiredRoles={[
          UserRole.Merchandiser,
          UserRole.InStoreStaff,
          UserRole.RetailManager,
          UserRole.Manager,
          UserRole.RegionalManager,
          UserRole.Packhouse,
        ]}
      >
        <Home />
      </RouteGuard>
    ),
  },
  {
    path: "/stores",
    element: (
      <RouteGuard
        requiredRoles={[
          UserRole.Merchandiser,
          UserRole.InStoreStaff,
          UserRole.RetailManager,
          UserRole.RegionalManager,
          UserRole.Manager,
        ]}
      >
        <StorePicker pathStart="/stores/" pathEnd="" />
      </RouteGuard>
    ),
  },
  {
    path: "/storesettings",
    element: (
      <RouteGuard requiredRoles={[UserRole.Manager]}>
        <StorePicker pathStart="/stores/" pathEnd="/settings" />
      </RouteGuard>
    ),
  },
  {
    path: "/stores/:storeid",
    element: (
      <RouteGuard
        requiredRoles={[
          UserRole.Merchandiser,
          UserRole.InStoreStaff,
          UserRole.RetailManager,
          UserRole.RegionalManager,
          UserRole.Manager,
        ]}
      >
        <OrderingHome />
      </RouteGuard>
    ),
  },
  {
    path: "/stores/:storeid/settings",
    element: (
      <RouteGuard requiredRoles={[UserRole.Manager]}>
        <StoreSettings />
      </RouteGuard>
    ),
  },
  {
    path: "/stores/:storeid/kpi",
    element: (
      <RouteGuard
        requiredRoles={[
          UserRole.Merchandiser,
          UserRole.InStoreStaff,
          UserRole.RetailManager,
          UserRole.RegionalManager,
          UserRole.Manager,
        ]}
      >
        <StoreKpis />
      </RouteGuard>
    ),
  },
  {
    path: "/stores/:storeid/shiftphotos/:photoType",
    element: (
      <RouteGuard
        requiredRoles={[
          UserRole.Merchandiser,
          UserRole.InStoreStaff,
          UserRole.RetailManager,
          UserRole.RegionalManager,
          UserRole.Manager,
        ]}
      >
        <StorePhoto />
      </RouteGuard>
    ),
  },
  {
    path: "/stores/:storeid/wastage",
    element: (
      <RouteGuard
        requiredRoles={[
          UserRole.Merchandiser,
          UserRole.InStoreStaff,
          UserRole.RetailManager,
          UserRole.RegionalManager,
          UserRole.Manager,
        ]}
      >
        <StoreWastage />
      </RouteGuard>
    ),
  },
  {
    path: "/stores/:storeid/orders",
    element: (
      <RouteGuard
        requiredRoles={[
          UserRole.Merchandiser,
          UserRole.InStoreStaff,
          UserRole.RetailManager,
          UserRole.RegionalManager,
          UserRole.Manager,
        ]}
      >
        <CurrentOrders />
      </RouteGuard>
    ),
  },
  {
    path: "/stores/:storeid/orders/view/:orderid",
    element: (
      <RouteGuard
        requiredRoles={[
          UserRole.Merchandiser,
          UserRole.InStoreStaff,
          UserRole.RetailManager,
          UserRole.RegionalManager,
          UserRole.Manager,
        ]}
      >
        <ViewOrderDetail />
      </RouteGuard>
    ),
  },
  {
    path: "/stores/:storeid/orders/:orderType",
    element: (
      <RouteGuard
        requiredRoles={[
          UserRole.Merchandiser,
          UserRole.InStoreStaff,
          UserRole.RetailManager,
          UserRole.RegionalManager,
          UserRole.Manager,
        ]}
      >
        <NewOrder />
      </RouteGuard>
    ),
  },
  {
    path: "/stores/:storeid/orders/:orderType/:deliveryDate",
    element: (
      <RouteGuard
        requiredRoles={[
          UserRole.Merchandiser,
          UserRole.InStoreStaff,
          UserRole.RetailManager,
          UserRole.RegionalManager,
          UserRole.Manager,
        ]}
      >
        <NewOrder />
      </RouteGuard>
    ),
  },
  {
    path: "/settings",
    element: (
      <RouteGuard requiredRoles={[UserRole.Manager]}>
        <PortalSettings />
      </RouteGuard>
    ),
  },
  {
    path: "/settings/budgetmultipliers/new",
    element: (
      <RouteGuard requiredRoles={[UserRole.Manager]}>
        <BudgetMultiplierNew />
      </RouteGuard>
    ),
  },
  {
    path: "/settings/budgetmultipliers/:multiplierId",
    element: (
      <RouteGuard requiredRoles={[UserRole.Manager]}>
        <BudgetMultiplierEdit />
      </RouteGuard>
    ),
  },
  {
    path: "/reviewcreditrequests",
    element: (
      <RouteGuard requiredRoles={[UserRole.Manager, UserRole.RegionalManager]}>
        <ReviewCreditRequests />
      </RouteGuard>
    ),
  },
  {
    path: "/reviewcreditrequests/:creditrequestid",
    element: (
      <RouteGuard requiredRoles={[UserRole.Manager, UserRole.RegionalManager]}>
        <CreditRequestDetails />
      </RouteGuard>
    ),
  },
  {
    path: "/viewmerchandisecreditrequests",
    element: (
      <RouteGuard requiredRoles={[UserRole.Merchandiser]}>
        <ViewMerchandiseCreditRequests />
      </RouteGuard>
    ),
  },
  {
    path: "/viewmerchandisecreditrequests/:creditrequestid",
    element: (
      <RouteGuard requiredRoles={[UserRole.Merchandiser]}>
        <ViewMerchandiseCreditRequestsDetails />
      </RouteGuard>
    ),
  },
  {
    path: "/settings/users",
    element: (
      <RouteGuard requiredRoles={[UserRole.Manager]}>
        <Users />
      </RouteGuard>
    ),
  },
  {
    path: "/settings/users/new",
    element: (
      <RouteGuard requiredRoles={[UserRole.Manager]}>
        <UserNew />
      </RouteGuard>
    ),
  },
  {
    path: "/settings/users/:userEmail",
    element: (
      <RouteGuard requiredRoles={[UserRole.Manager]}>
        <UserEdit />
      </RouteGuard>
    ),
  },
  {
    path: "/orders",
    element: (
      <RouteGuard
        requiredRoles={[
          UserRole.RetailManager,
          UserRole.RegionalManager,
          UserRole.Manager,
        ]}
      >
        <Orders />
      </RouteGuard>
    ),
  },
  {
    path: "/orders/:orderType",
    element: (
      <RouteGuard
        requiredRoles={[
          UserRole.RetailManager,
          UserRole.RegionalManager,
          UserRole.Manager,
        ]}
      >
        <Orders />
      </RouteGuard>
    ),
  },
  {
    path: "/orders/:orderType/unprocessed",
    element: (
      <RouteGuard
        requiredRoles={[
          UserRole.RetailManager,
          UserRole.RegionalManager,
          UserRole.Manager,
        ]}
      >
        <UnprocessedOrders />
      </RouteGuard>
    ),
  },
  {
    path: "/orders/store",
    element: (
      <RouteGuard
        requiredRoles={[
          UserRole.Merchandiser,
          UserRole.InStoreStaff,
          UserRole.RetailManager,
          UserRole.RegionalManager,
          UserRole.Manager,
        ]}
      >
        <StorePicker pathStart="/orders/" pathEnd="" />
      </RouteGuard>
    ),
  },
  {
    path: "/orders/upload",
    element: (
      <RouteGuard requiredRoles={[UserRole.Manager]}>
        <UploadPreOrders />
      </RouteGuard>
    ),
  },
  {
    path: "/budgets/upload/:budgettype",
    element: (
      <RouteGuard requiredRoles={[UserRole.Manager]}>
        <UploadBudgets />
      </RouteGuard>
    ),
  },
  {
    path: "/inventory/availability",
    element: (
      <RouteGuard requiredRoles={[UserRole.Manager]}>
        <ProductAvailability />
      </RouteGuard>
    ),
  },
  {
    path: "/inventory/availability/new",
    element: (
      <RouteGuard requiredRoles={[UserRole.Manager]}>
        <NewProductAvailability />
      </RouteGuard>
    ),
  },
  {
    path: "/inventory/availability/:recId",
    element: (
      <RouteGuard requiredRoles={[UserRole.Manager]}>
        <ProductAvailability />
      </RouteGuard>
    ),
  },
  {
    path: "/pick",
    element: (
      <RouteGuard requiredRoles={[UserRole.Packhouse, UserRole.Manager]}>
        <PickOrders picking={true} packing={false} />
      </RouteGuard>
    ),
  },
  {
    path: "/pick/:orderID",
    element: (
      <RouteGuard requiredRoles={[UserRole.Packhouse, UserRole.Manager]}>
        <PickOrder picking={true} packing={false} />
      </RouteGuard>
    ),
  },
  {
    path: "/pick/next",
    element: (
      <RouteGuard requiredRoles={[UserRole.Packhouse, UserRole.Manager]}>
        <NextOrder pick={true} pack={false} />
      </RouteGuard>
    ),
  },
  {
    path: "/pack",
    element: (
      <RouteGuard requiredRoles={[UserRole.Packhouse, UserRole.Manager]}>
        <PickOrders picking={false} packing={true} />
      </RouteGuard>
    ),
  },
  {
    path: "/pack/:orderID",
    element: (
      <RouteGuard requiredRoles={[UserRole.Packhouse, UserRole.Manager]}>
        <PickOrder picking={false} packing={true} />
      </RouteGuard>
    ),
  },
  {
    path: "/pack/next",
    element: (
      <RouteGuard requiredRoles={[UserRole.Packhouse, UserRole.Manager]}>
        <NextOrder pick={false} pack={true} />
      </RouteGuard>
    ),
  },
  {
    path: "/vantransfers",
    element: (
      <RouteGuard
        requiredRoles={[
          UserRole.Manager,
          UserRole.RetailManager,
          UserRole.RegionalManager,
        ]}
      >
        <NewTransfer />
      </RouteGuard>
    ),
  },
  {
    path: "*",
    element: (
      <RouteGuard requiredRoles={[]}>
        <NotFound />
      </RouteGuard>
    ),
  },
];

export default AppRoutes;
