import { useCallback, useMemo, useRef } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import { useNavigate } from "react-router-dom";
//import "./ProductAvailabilityStores.scss";
import { RowClickedEvent, ValueFormatterParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import dayjs from "dayjs";
import Nav from "react-bootstrap/Nav";
import { CreditRequestDTO } from "../../../services/ApiClient";
import StatusCellRenderer from "./StatusCellRenderer";

interface ICreditRequestTableProps {
  creditRequestData: CreditRequestDTO[];
  onNavItemClicked(eventKey: string): void;
  menuState: string;
}

export const ReviewCreditTable = (props: ICreditRequestTableProps) => {
  const navigate = useNavigate();
  //const [error, setError] = useState<null | string>(null);
  //const [isLoaded, setIsLoaded] = useState(false);
  //const [isFetching, setIsFetching] = useState(false);
  //const [rowData, setRowData] = useState<any[]>([]);
  const gridRef = useRef<AgGridReact<any>>(null);

  const onRowSelected = useCallback(
    (selectedRow: RowClickedEvent) => {
      const creditrequestdata = selectedRow.data;

      navigate(`/reviewcreditrequests/${creditrequestdata.creditrequestid}`);
    },
    [navigate]
  );

  const dateFormatter = useCallback((params: ValueFormatterParams) => {
    return dayjs(params.value).format("DD/MM/YY");
  }, []);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
    }),
    []
  );

  const columnDefs = useMemo(
    () => [
      {
        headerName: "Status",
        field: "status",
        minWidth: 100,
        maxWidth: 100,
        cellRendererFramework: StatusCellRenderer,
      },
      {
        headerName: "Requested by",
        field: "requestedBy",
        minWidth: 210,
        type: "numberColumn",
      },
      {
        headerName: "Store",
        field: "storeName",
        minWidth: 210,
      },
      {
        headerName: "Delivery date",
        field: "deliveryDate",
        minWidth: 210,
        valueFormatter: dateFormatter,
      },
      {
        headerName: "Product",
        field: "productName",
        minWidth: 200,
        maxWidth: 300,
      },
      {
        headerName: "Quantity",
        field: "quantity",
        minWidth: 110,
        maxWidth: 110,
      },
      //{ Removed as requested in 75321 dev ops item
      //  headerName: 'Price',
      //  field: 'price',
      //  flex: 3,
      //  type: "numberColumn",
      //  valueFormatter: priceFormatter
      //},
      {
        headerName: "Comment",
        field: "comment",
        minWidth: 250,
        flex: 1,
        multiLine: true,
      },
    ],
    []
  );

  return (
    <Row className="gy-3 cr-table">
      <Col md={12}>
        <Stack gap={3} direction="horizontal">
          <h5>Credit Requests</h5>
          <Stack gap={3} direction="horizontal" className="ms-auto">
            {
              //<Button type="button" variant="primary" onClick={props.saveAvailabilityItems}>
              //Save
              //</Button>
            }
          </Stack>
        </Stack>
      </Col>
      <Col md={12}>
        <>
          <Card className="p-0">
            <Card.Header>
              <Nav
                variant="tabs"
                defaultActiveKey={props.menuState}
                onSelect={(e) => props.onNavItemClicked(e!)}
              >
                <Nav.Item>
                  <Nav.Link eventKey={"pending"}>Pending</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey={"complete"}>
                    Approved/Rejected (30 days)
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>
            <Card.Body className="p-0 available-products-card border-light shadow-sm ">
              <div className="ag-theme-alpine ag-row-pointer available-products-container">
                <AgGridReact
                  columnDefs={columnDefs}
                  rowData={props.creditRequestData}
                  defaultColDef={defaultColDef}
                  domLayout="autoHeight"
                  onRowClicked={onRowSelected}
                  ref={gridRef}
                  rowSelection={"single"}
                  pagination={true}
                  paginationPageSize={10}
                  //onCellValueChanged={onCellValueChanged}
                />
              </div>
            </Card.Body>
          </Card>
        </>
      </Col>
    </Row>
  );
};
