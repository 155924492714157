import React from "react";
import { CreditrequestStatus } from "../../../services/ApiClient";

interface StatusCellRendererProps {
  value: number; // Change the type accordingly if it's not a number
}

const StatusCellRenderer: React.FC<StatusCellRendererProps> = ({ value }) => {
  let statusLabel = "";

  if (!isNaN(value)) {
    const statusValue = parseInt(value.toString(), 10);

    if (CreditrequestStatus[statusValue]) {
      statusLabel = CreditrequestStatus[statusValue];
    }
  }

  return <div>{statusLabel}</div>;
};

export default StatusCellRenderer;
