import dayjs from "dayjs";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Stack from "react-bootstrap/Stack";
import { LinkContainer } from "react-router-bootstrap";
import { useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import {
  ApiException,
  OrderClient,
  OrderType,
  StoreDTO,
} from "../../../services/ApiClient";
import { CardPlaceholder } from "../../common/cardPlaceholder/CardPlaceholder";
import { NoCards } from "../../layouts/NoCards";
import "./CurrentOrders.scss";

interface INewOrderProps {}

export const CurrentOrders = (props: INewOrderProps) => {
  const params = useParams();
  const [error, setError] = useState<null | string>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [storeData, setStoreData] = useImmer<StoreDTO | null>(null);

  useEffect(() => {
    const fetchNewOrder = async () => {
      try {
        setIsFetching(true);

        const storeid = params.storeid;

        if (storeid) {
          const result = await new OrderClient(
            process.env.REACT_APP_API_BASE
          ).getStoreCurrentOrders(storeid);

          //sort by date
          result.currentOrders.sort((a, b) =>
            dayjs(a.deliveryDate).isBefore(dayjs(b.deliveryDate)) ? 1 : -1
          );
          setStoreData(result);
        }
        setIsLoaded(true);
        setIsFetching(false);
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        setIsFetching(false);
        setError(JSON.parse(error.response).error);
      }
    };

    fetchNewOrder().catch(console.error);
  }, [params.storeid, setStoreData]);

  return (
    <NoCards
      pageTitle={
        storeData?.name ? `Current orders - ${storeData?.name}` : undefined
      }
      className={"current-orders"}
    >
      {!isFetching && isLoaded ? (
        <>
          {storeData?.currentOrders && storeData?.currentOrders.length > 0 ? (
            <>
              {storeData.currentOrders.map((order) => (
                <Col xs={12} lg={12}>
                  <div>
                    <Card className="shadow-sm order-card" border="light">
                      <LinkContainer to={`view/${order.orderId}`}>
                        <div className="order-card-inner">
                          <Stack gap={3} direction="horizontal">
                            <h5>{`${OrderType[order.orderType]} ${dayjs(
                              order.deliveryDate
                            ).format("DD/MM/YYYY")} $${order.total}`}</h5>
                            <LinkContainer to={`view/${order.orderId}`}>
                              <Button className={`ms-auto`}>View</Button>
                            </LinkContainer>
                          </Stack>
                        </div>
                      </LinkContainer>
                    </Card>
                  </div>
                </Col>
              ))}
            </>
          ) : (
            <Col lg={12}>
              <Card className="shadow-sm p-0" border="light">
                <Card.Body className="p-0 available-products-card">
                  <div className="order-card-inner">
                    <h5>No orders available.</h5>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          )}
        </>
      ) : (
        <Col xs={12} lg={12}>
          <Card className="shadow-sm p-0" border="light">
            <Card.Body className="p-0 available-products-card">
              <CardPlaceholder />
            </Card.Body>
          </Card>
        </Col>
      )}
      <Col lg={12} className={"d-flex flex-row-reverse"}>
        <Stack gap={3} direction="horizontal">
          <LinkContainer to={`/stores/${params.storeid}`}>
            <Button type="button" variant="light">
              {"Back"}
            </Button>
          </LinkContainer>
        </Stack>
      </Col>
      <Modal show={error?.length! > 0} onHide={() => setError(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setError(null)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </NoCards>
  );
};
