import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import {
  ApiException,
  CreditRequestDTO,
  CreditrequestStatus,
  OrderClient,
  FileuploadClient,
} from "../../../services/ApiClient";
import { SingleCard } from "../../layouts/SingleCard";

interface IViewMerchandiseCreditRequestsFormProps {
  creditrequestdata?: CreditRequestDTO;
}

export const ViewMerchandiseCreditRequestsDetails = (
  props: IViewMerchandiseCreditRequestsFormProps
) => {
  //const [error, setError] = useState<null | string>(null);
  //const [isLoaded, setIsLoaded] = useState(false);
  //const [isFetching, setIsFetching] = useState(false);
  const { creditrequestid } = useParams();
  const [creditRequestItem, setCreditRequestItem] =
    useState<CreditRequestDTO | null>(null);
  const [images, setImages] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        //setIsFetching(true);

        const result =
          await new OrderClient().getMerchandiserOpenCreditRequests();
        setCreditRequestItem(
          result.find(
            (item) => item.creditrequestid === Number(creditrequestid)
          ) as CreditRequestDTO
        );

        //setIsLoaded(true);
        //setIsFetching(false);
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        //setIsFetching(false);
        //setError(JSON.parse(error.response).error);
      }
    };

    fetchData().catch(console.error);
  }, [creditrequestid]);

  useEffect(() => {
    if (creditRequestItem?.photo!) {
      const fetchData = async () => {
        try {
          let photos = creditRequestItem?.photo?.split(";").slice(0, -1);

          if (photos!.length! === 0 && creditRequestItem?.photo!.length! > 0) {
            photos = [creditRequestItem?.photo!];
          }

          let photoUrls: string[] = [];

          if (photos!.length! > 0) {
            await Promise.all(
              photos!.map(async (photo) => {
                const result = await new FileuploadClient().get(photo);
                const imageUrl = (await result!.data.text()).replaceAll(
                  '"',
                  ""
                );
                photoUrls.push(imageUrl);
              })
            );

            setImages(photoUrls);
          }
        } catch (e: unknown) {
          const error = e as ApiException;
          console.log(error.message);
          //setIsFetching(false);
          //setError(JSON.parse(error.response).error);
        }
      };

      fetchData().catch(console.error);
    }
  }, [creditRequestItem?.photo]);

  return (
    <>
      {creditRequestItem && (
        <SingleCard pageTitle="Credit request">
          <div className="portal-settings-form">
            <Formik initialValues={creditRequestItem} onSubmit={() => {}}>
              {({ handleSubmit, values }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Row className="">
                    <Form.Group className="mb-3">
                      <Form.Label>Sales order</Form.Label>
                      <Form.Control
                        type="number"
                        name="salesOrderId"
                        disabled
                        value={values.salesOrderId}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Quantity</Form.Label>
                      <Form.Control
                        type="number"
                        name="quantity"
                        disabled
                        value={values.quantity || ""}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Price</Form.Label>
                      <InputGroup className="fd-input-container">
                        <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                        <Form.Control
                          type="number"
                          name="price"
                          disabled
                          value={values.price}
                        />
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Reason</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="reason"
                        rows={3}
                        disabled
                        value={values.reason}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Comment</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="comment"
                        rows={3}
                        disabled
                        value={values.comment || ""}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Photos</Form.Label>
                      {images!.length! > 0 ? (
                        <>
                          {images!.map((image) => (
                            <div>
                              <img
                                width={"80%"}
                                src={image}
                                title=""
                                alt={"Credit evidence"}
                              />
                            </div>
                          ))}
                        </>
                      ) : (
                        <div>No photos</div>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        name="status"
                        disabled
                        value={values.status || ""}
                      >
                        {Object.keys(CreditrequestStatus).map((key) => (
                          <option
                            key={key}
                            value={
                              CreditrequestStatus[
                                key as keyof typeof CreditrequestStatus
                              ]
                            }
                          >
                            {key}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>

                    {values.status === CreditrequestStatus.Reject && (
                      <Form.Group className="mb-3">
                        <Form.Label>Reject Reason</Form.Label>
                        <Form.Control
                          type="text"
                          id="rejectReason"
                          name="rejectReason"
                          disabled
                        />
                      </Form.Group>
                    )}
                    <Form.Group className="mb-3">
                      <Form.Label>Product Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="productName"
                        disabled
                        value={values.productName || ""}
                      />
                    </Form.Group>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        </SingleCard>
      )}
    </>
  );
};
