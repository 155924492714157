import { RowClassParams, ValueFormatterParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  ApiException,
  EDeliveryMode,
  OrderClient,
  PickPackStatsDTO,
} from "../../../services/ApiClient";

interface IPickPageStatsTableProps {
  date?: string;
  orderType?: number;
  deliveryMode?: number;
}

export const PickPackStatsTable = (props: IPickPageStatsTableProps) => {
  //const [error, setError] = useState<null | string>(null);
  //const [isLoaded, setIsLoaded] = useState(false);
  //const [isFetching, setIsFetching] = useState(false);
  const [stats, setStats] = useState<PickPackStatsDTO[] | null>(null);

  const formatPercent = (params: ValueFormatterParams) => {
    return `${params.value.toFixed(0)}%`;
  };

  const formatDate = (params: ValueFormatterParams) => {
    return dayjs(params.value).format("DD/MM/YYYY");
  };

  const [columnDefs] = useState([
    {
      headerName: "Day",
      field: "stateDate",
      flex: 1,
      valueFormatter: formatDate,
    },
    {
      headerName: "Orders",
      field: "totalOrders",
      flex: 1,
    },
    {
      headerName: "Picked",
      field: "totalPicked",
      flex: 1,
    },
    {
      headerName: "Packed",
      field: "totalPacked",
      flex: 1,
    },
    {
      headerName: "% Picked",
      field: "pickedPct",
      flex: 1,
      valueFormatter: formatPercent,
    },
    {
      headerName: "% Packed",
      field: "packedPct",
      flex: 1,
      valueFormatter: formatPercent,
    },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!props.date) {
          //setIsFetching(true);
          let result = await new OrderClient(
            process.env.REACT_APP_API_BASE
          ).getPickPackStats(props.date);

          if (props.date) {
            result = result.filter(
              (x) =>
                dayjs(x.stateDate).format("DD/MM/YYYY") ===
                dayjs(props.date).format("DD/MM/YYYY")
            );
          }
          setStats(result);
        } else if (props.date && props.orderType) {
          const result = await new OrderClient(
            process.env.REACT_APP_API_BASE
          ).getPickPackStatsForDay(
            props.orderType,
            props.deliveryMode ?? EDeliveryMode.ALL,
            props.date
          );

          setStats(result);
        }

        //setIsLoaded(true);
        //setIsFetching(false);
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        //setIsFetching(false);
        //setError(JSON.parse(error.response).error);
      }
    };

    fetchData().catch(console.error);
  }, [props.date, props.deliveryMode, props.orderType]);

  const getRowStyle = (params: RowClassParams) => {
    if (
      dayjs(params.data.stateDate).format("DD/MM/YYYY") ===
      dayjs().format("DD/MM/YYYY")
    ) {
      return { background: "#CCE7CB" };
    }
  };

  return (
    <div className="ag-theme-alpine ag-row-pointer pick-pack-stats">
      <AgGridReact
        columnDefs={columnDefs}
        rowData={stats ?? []}
        domLayout="autoHeight"
        getRowStyle={getRowStyle}
      />
    </div>
  );
};
