import { ICellRendererParams, ValueFormatterParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Stack from "react-bootstrap/Stack";
import { LinkContainer } from "react-router-bootstrap";
import { useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import {
  ApiException,
  DeliveryDateDTO,
  ItemType,
  OrderClient,
  OrderDTO,
  UnprocessedViewDTO,
} from "../../../services/ApiClient";
import { CardPlaceholder } from "../../common/cardPlaceholder/CardPlaceholder";
import { DeliveryDatePicker } from "../../common/deliveryDatePicker/DeliveryDatePicker";
import { NoCards } from "../../layouts/NoCards";
import { DeleteConfirmModal } from "./DeleteConfirmModal";
import "./UnprocessedOrders.scss";
import { UpdateDateConfirmModal } from "./UpdateDateConfirmModal";

interface INewOrderProps {}

export const UnprocessedOrders = (props: INewOrderProps) => {
  const params = useParams();
  const [error, setError] = useState<null | string>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [ordersDTO, setOrdersDto] = useImmer<UnprocessedViewDTO | null>(null);
  const [possibleDeilveryDates, setPossibleDilveryDates] = useState<
    DeliveryDateDTO[]
  >([]);
  const [currentDeliveryDate, setCurrentDeliveryDate] =
    useState<DeliveryDateDTO | null>(null);
  //const [defaultDeliveryDate, setDefaultDeliveryDate] =
  //  useState<DeliveryDateDTO | null>(null);
  const [hasUpdated, setHasUpdated] = useState<boolean>(true);
  const [itemToDelete, setItemToDelete] = useState<OrderDTO | null>(null);
  const [itemUpateDate, setItemUpateDate] = useState<OrderDTO | null>(null);
  //const [orderType, setOrderType] = useState<number | null>(null);

  useEffect(() => {
    const fetchNewOrder = async () => {
      try {
        if (hasUpdated) {
          setIsFetching(true);

          const orderType = params.orderType;
          let orderTypeIndex;

          //Pots = 0,
          //Cuts = 1,
          //Materials = 2,

          if (orderType === "pots") {
            orderTypeIndex = 0;
          } else if (orderType === "cut") {
            orderTypeIndex = 1;
          } else if (orderType === "materials") {
            orderTypeIndex = 2;
          }

          //setOrderType(orderTypeIndex ?? null);

          if (currentDeliveryDate?.actualValue) {
            const filterDate = currentDeliveryDate?.actualValue;
            const ordersDTOResult = await new OrderClient(
              process.env.REACT_APP_API_BASE
            ).unconfirmedOrders(orderTypeIndex, filterDate);
            setOrdersDto(ordersDTOResult);
            setPossibleDilveryDates(ordersDTOResult.possibleDeliveryDates);
          } else {
            const ordersDTOResult = await new OrderClient(
              process.env.REACT_APP_API_BASE
            ).unconfirmedOrders(orderTypeIndex);
            setOrdersDto(ordersDTOResult);
            setPossibleDilveryDates(ordersDTOResult.possibleDeliveryDates);
          }

          setIsLoaded(true);
          setIsFetching(false);
          setHasUpdated(false);
        }
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        setIsFetching(false);
        setError(JSON.parse(error.response).error);
      }
    };

    fetchNewOrder().catch(console.error);
  }, [currentDeliveryDate, hasUpdated, params.orderType, setOrdersDto]);

  const setDeliveryDate = useCallback(
    (delvieryDate: DeliveryDateDTO) => {
      setCurrentDeliveryDate(delvieryDate);
      setHasUpdated(true);
    },
    [setCurrentDeliveryDate]
  );

  const deleteOrder = useCallback(
    async (orderId: number) => {
      try {
        await new OrderClient(process.env.REACT_APP_API_BASE).deleteOrder(
          orderId
        );

        setOrdersDto((draft) => {
          const updateIndex = draft?.orders.findIndex(
            (order) => order.orderId === orderId
          );
          if (updateIndex !== -1) draft?.orders.splice(updateIndex!, 1);
        });

        setHasUpdated(true);
        setItemToDelete(null);
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        setIsFetching(false);
        setError(JSON.parse(error.response).error);
      }
    },
    [setOrdersDto]
  );

  const updateOrderDate = useCallback(
    async (orderId: number, orderDeliveryDate: string) => {
      try {
        await new OrderClient(
          process.env.REACT_APP_API_BASE
        ).updateDeliveryDate(orderId, orderDeliveryDate);

        const currentDeliveryDate = ordersDTO!.possibleDeliveryDates.find(
          (deliveryDate) => {
            return deliveryDate.actualValue === orderDeliveryDate;
          },
          []
        );

        if (currentDeliveryDate) {
          setCurrentDeliveryDate(currentDeliveryDate);
        }

        setHasUpdated(true);
        setItemUpateDate(null);
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        setIsFetching(false);
        setError(JSON.parse(error.response).error);
      }
    },
    [setHasUpdated, setItemUpateDate, ordersDTO]
  );

  const dateFormatter = (params: ValueFormatterParams) => {
    return dayjs(params.value).format("DD/MM/YY");
  };

  const DeleteCellRenderer = (props: ICellRendererParams): JSX.Element => {
    return (
      <div className={"btn-container"}>
        <Button
          size="sm"
          variant="danger"
          onClick={() => setItemToDelete(props.data)}
        >
          {"Delete"}
        </Button>
      </div>
    );
  };

  const ChangeDateCellRenderer = (props: ICellRendererParams): JSX.Element => {
    return (
      <div className={"btn-container"}>
        <Button
          size="sm"
          variant="primary"
          onClick={() => setItemUpateDate(props.data)}
        >
          {"Change delivery date"}
        </Button>
      </div>
    );
  };

  const columnDefs = useMemo(
    () => [
      {
        headerName: "Store",
        field: "store.name",
        flex: 4,
      },
      {
        headerName: "Delivery date",
        field: "deliveryDate",
        type: "dateColumn",
        valueFormatter: dateFormatter,
        flex: 2,
      },
      {
        headerName: "Order amount",
        field: "total",
        type: "number",
        flex: 2,
      },
      {
        headerName: "",
        field: "delete",
        flex: 1,
        cellRenderer: DeleteCellRenderer,
      },
      {
        headerName: "",
        field: "changeDeliveryDate",
        flex: 2,
        cellRenderer: ChangeDateCellRenderer,
      },
    ],
    []
  );

  console.log(itemUpateDate);
  return (
    <>
      <NoCards
        pageTitle={
          ordersDTO?.orderType
            ? `Unconfirmed orders for ${ItemType[ordersDTO?.orderType!]}`
            : undefined
        }
        className={"unprocessed-orders"}
      >
        {!isFetching && isLoaded ? (
          <>
            <Col lg={12}>
              {ordersDTO?.fromDate && (
                <DeliveryDatePicker
                  deliveryDates={possibleDeilveryDates}
                  currentValue={
                    currentDeliveryDate?.actualValue ?? ordersDTO?.fromDate!
                  }
                  setDeliveryDate={setDeliveryDate}
                  size="lg"
                />
              )}
            </Col>
            {ordersDTO?.orders && ordersDTO.orders.length > 0 ? (
              <Col lg={12}>
                <Card className="shadow-sm" border="light">
                  <div className="ag-theme-alpine ag-row-pointer available-products-container">
                    <AgGridReact
                      columnDefs={columnDefs}
                      rowData={ordersDTO.orders}
                      domLayout="autoHeight"
                      rowSelection={"single"}
                      pagination={true}
                      paginationPageSize={100}
                    />
                  </div>
                </Card>
              </Col>
            ) : (
              <Col lg={12}>
                <Card className="shadow-sm" border="light">
                  <div className="no-items">No orders available.</div>
                </Card>
              </Col>
            )}
            <Col lg={12} className={"d-flex flex-row-reverse"}>
              <Stack gap={3} direction="horizontal">
                <LinkContainer to={"/"}>
                  <Button type="button" variant="light">
                    {"Back"}
                  </Button>
                </LinkContainer>
              </Stack>
            </Col>
          </>
        ) : (
          <>
            <Col lg={12}>
              <h3> </h3>
            </Col>
            <Col lg={12}>
              <Card className="shadow-sm" border="light">
                <CardPlaceholder />
              </Card>
            </Col>
          </>
        )}
      </NoCards>
      <Modal show={error?.length! > 0} onHide={() => setError(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setError(null)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {itemToDelete !== null && (
        <DeleteConfirmModal
          orderId={itemToDelete.orderId}
          show={itemToDelete !== null}
          deleteItem={deleteOrder}
          handleClose={() => setItemToDelete(null)}
          storeName={itemToDelete?.store?.name!}
          date={itemToDelete?.deliveryDate!}
          orderAmount={itemToDelete?.total!}
        />
      )}
      {itemUpateDate !== null && (
        <UpdateDateConfirmModal
          orderId={itemUpateDate.orderId}
          show={itemUpateDate !== null}
          updateOrderDate={updateOrderDate}
          handleClose={() => setItemUpateDate(null)}
          storeName={itemUpateDate?.store?.name!}
          date={itemUpateDate?.deliveryDate!}
          orderAmount={itemUpateDate?.total!}
          possibleDelieryDates={ordersDTO?.newDeliveryDates!}
        />
      )}
    </>
  );
};
