import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { OrderDTO } from "../../../services/ApiClient";
import "./OrderHeader.scss";

interface OrderHeaderProps {
  order: OrderDTO;
}

const dateFormatter = (dateString: string) => {
  if (!dateString) {
    return "";
  }

  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};

/*const timeFormatter = (timeString: string | "undefined") => {
  if (!timeString) {
    return "";
  }

  const time = new Date(`1970-01-01T${timeString}`);

  let hours = time.getHours();
  const minutes = time.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";

  hours = hours % 12;
  hours = hours || 12;

  const formattedTime = `${hours}:${minutes
    .toString()
    .padStart(2, "0")}:${"00"} ${ampm}`;

  return formattedTime;
};*/

export const OrderHeader: React.FC<OrderHeaderProps> = ({ order }) => {
  return (
    <div className="order-header">
      <Row>
        <Col lg={4}>
          <h3>Pick-Pack Order</h3>
        </Col>
        <Col lg={8}>
          <div className="order-details">
            <div className="order-deliver-to">
              <div>{order.store?.name}</div>
            </div>
            <div className="order-details-row">
              <span>Order: </span>
              <span>{order.orderId}</span>
            </div>
            <div className="order-details-row">
              <span>Customer number: </span>
              <span>{order.store?.customerNumber}</span>
            </div>
            <div className="order-details-row">
              <span>Delivery date: </span>
              <span>{dateFormatter(order.deliveryDate || "")}</span>
            </div>
            {
              //<div className="order-details-row">
              //    <span>Expected Pickup Time: </span>
              //    <span>{timeFormatter(order.pickedTime || '')}</span>
              //</div>
            }
          </div>
          {
            //<div className="order-code">Code: <strong>{order.unleashedSalesOrderId}</strong></div>
          }
        </Col>
      </Row>
    </div>
  );
};

export default OrderHeader;
