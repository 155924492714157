import { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  ApiException,
  CreditRequestDTO,
  OrderClient,
} from "../../../services/ApiClient";
import { NoCards } from "../../layouts/NoCards";
import { ViewMerchandiseCreditRequestsTable } from "./MerchandiseCreditRequestsTable";
import { CardPlaceholder } from "../../common/cardPlaceholder/CardPlaceholder";
import { useImmer } from "use-immer";
import "./MerchandiseCreditRequests.scss";

interface IViewMerchandiseCreditRequestsProps {}

export const ViewMerchandiseCreditRequests = (
  props: IViewMerchandiseCreditRequestsProps
) => {
  const [error, setError] = useState<null | string>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [creditRequestData, setCreditRequestData] = useImmer<
    CreditRequestDTO[] | null
  >(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsFetching(true);

        const result =
          await new OrderClient().getMerchandiserOpenCreditRequests();

        setCreditRequestData(result);
        setIsLoaded(true);
        setIsFetching(false);
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        setIsFetching(false);
        setError(JSON.parse(error.response).error);
      }
    };

    fetchData().catch(console.error);
  }, [setCreditRequestData]);

  return (
    <div className={""}>
      <NoCards pageTitle="">
        {!isFetching && isLoaded && creditRequestData ? (
          <>
            <Col lg={12}>
              <ViewMerchandiseCreditRequestsTable
                creditRequestData={creditRequestData}
              />
            </Col>
          </>
        ) : (
          <CardPlaceholder />
        )}
      </NoCards>
      <Modal show={error?.length! > 0} onHide={() => setError(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setError(null)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
