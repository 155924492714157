import { ICellRendererParams } from "ag-grid-community";
import { useCallback } from "react";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import { useSearchParams } from "react-router-dom";
import "./NavButtonRenderer.scss";

interface INavButtonRendererProps {
  label: string;
  to?: string;
  includeQueryString?: boolean;
  navigate?: (data: unknown) => void;
}

export const NavButtonRenderer = (
  props: ICellRendererParams & INavButtonRendererProps
) => {
  const [searchParams] = useSearchParams();

  const onClick = useCallback(() => {
    props.navigate && props.navigate(props.data);
  }, [props]);

  return (
    <div className="nav-button-container">
      {props.value && typeof props.navigate === "undefined" ? (
        <LinkContainer
          to={{
            pathname: `${props.to}/${props.value}`,
            search: `${
              props.includeQueryString ? searchParams.toString() : ""
            }`,
          }}
        >
          <Button size="sm">{props.label}</Button>
        </LinkContainer>
      ) : (
        <Button onClick={onClick} size="sm">
          {props.label}
        </Button>
      )}
    </div>
  );
};
