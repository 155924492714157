import { ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { forwardRef, useCallback, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { ItemDTO, OrderDTO } from "../../../services/ApiClient";
import { ZeroButton } from "./ZeroButton";

interface IZeroTableTableProps {
  availableItems: ItemDTO[];
  orders: OrderDTO[];
  alignedGrids: AgGridReact<any>[] | undefined;
  zeroValuesForItem: (itemRecId: number) => void;
}

interface ITotals {
  customers: number;
  total: number;
  wtd: number;
  mtd: number;
  budget: number;
  used?: number;
}

export const ZeroTable = forwardRef<AgGridReact, IZeroTableTableProps>(
  (props: IZeroTableTableProps, ref) => {
    const params = useParams();
    const [ZeroTableColumnDefs, setZeroTableColumnDefs] = useState([]);
    const [ZeroTableRowData, setZeroTableRowData] = useState<any[]>([]);

    const setZeroTableColumns = useCallback(
      (availableItems: ItemDTO[], totals: ITotals) => {
        const columnDefs = [
          {
            headerName: "T",
            field: "teamLeadReviewed",
            pinned: "left",
            editable: false,
            width: 40,
            cellClass: "edit-indicator-cell",
            headerClass: "edit-indicator-heading",
            sortable: true,
          },
          {
            headerName: "M",
            field: "managerChanged",
            pinned: "left",
            editable: false,
            width: 40,
            cellClass: "edit-indicator-cell",
            headerClass: "edit-indicator-heading",
          },
          {
            headerName: "Customer",
            field: "store.name",
            pinned: "left",
            editable: false,
            width: 190,
            sortable: true,
            resizable: true,
            cellRenderer: (params: ICellRendererParams<any>) => {
              return <span>Total orders: {totals.customers}</span>;
            },
          },
          {
            headerName: "This order",
            field: "total",
            pinned: "left",
            editable: false,
            width: 105,
            autoHeaderHeight: true,
            wrapHeaderText: true,
            cellRenderer: (params: ICellRendererParams<any>) => {
              return (
                <OverlayTrigger
                  key={"top"}
                  placement={"top"}
                  overlay={
                    <Tooltip id={`orderTotal`}>
                      <span>${totals.total.toFixed(2)}</span>
                    </Tooltip>
                  }
                >
                  <span>{totals.total.toFixed(0)}</span>
                </OverlayTrigger>
              );
            },
          },
          ...(params.orderType === "cut"
            ? [
                {
                  headerName: "WTD",
                  field: "store.totalWeekToDate",
                  pinned: "left",
                  editable: false,
                  width: 84,
                  cellRenderer: (params: ICellRendererParams<any>) => {
                    return (
                      <OverlayTrigger
                        key={"top"}
                        placement={"top"}
                        overlay={
                          <Tooltip id={`orderTotalWtd`}>
                            <span>${totals.wtd.toFixed(2)}</span>
                          </Tooltip>
                        }
                      >
                        <span>{totals.wtd.toFixed(0)}</span>
                      </OverlayTrigger>
                    );
                  },
                },
              ]
            : []),
          ...(params.orderType === "pots"
            ? [
                {
                  headerName: "MTD",
                  field: "store.totalMonth",
                  pinned: "left",
                  editable: false,
                  width: 84,
                  cellRenderer: (params: ICellRendererParams<any>) => {
                    return (
                      <OverlayTrigger
                        key={"top"}
                        placement={"top"}
                        overlay={
                          <Tooltip id={`orderTotalMtd`}>
                            <span>${totals.mtd.toFixed(2)}</span>
                          </Tooltip>
                        }
                      >
                        <span>{totals.mtd.toFixed(0)}</span>
                      </OverlayTrigger>
                    );
                  },
                },
              ]
            : []),
          {
            headerName: "Budget",
            field: "store.budget",
            pinned: "left",
            editable: false,
            width: 94,
            cellRenderer: (params: ICellRendererParams<any>) => {
              return (
                <OverlayTrigger
                  key={"top"}
                  placement={"top"}
                  overlay={
                    <Tooltip id={`orderTotalBudget`}>
                      <span>${totals.budget.toFixed(0)}</span>
                    </Tooltip>
                  }
                >
                  <span>{totals.budget.toFixed(0)}</span>
                </OverlayTrigger>
              );
            },
          },
          {
            headerName: "Used",
            field: "used",
            pinned: "left",
            editable: false,
            width: 84,
            sortable: true,
            cellRenderer: (params: ICellRendererParams<any>) => {
              return <span>{totals.used!.toFixed(0)}%</span>;
            },
          },
        ] as any;

        availableItems.forEach((item) => {
          columnDefs.push({
            headerName: `${item.itemName}`,
            field: `${item.recId}`,
            autoHeaderHeight: true,
            wrapHeaderText: true,
            width: 120,
            sortable: true,
            cellRendererSelector: (params: ICellRendererParams<any>) => {
              const button = {
                component: ZeroButton,
              };
              return button;
            },
            cellRendererParams: {
              zeroValuesForItem: props.zeroValuesForItem,
              recId: item.recId,
            },
          });
        });

        setZeroTableColumnDefs(columnDefs);
      },
      [params.orderType, props.zeroValuesForItem]
    );

    const setZeroTableRows = useCallback((availableItems: ItemDTO[]) => {
      const rowsMap = new Map([["zeroRow", {} as any]]);
      const rowsArr = Array.from(rowsMap.values());

      setZeroTableRowData(rowsArr);
    }, []);

    useEffect(() => {
      setZeroTableRows(props.availableItems);
    }, [props.availableItems, setZeroTableRows]);

    useEffect(() => {
      const totals = props.orders.reduce(
        (acumulator, currentValue) => {
          let totals = {
            customers: (acumulator.customers += 1),
            total: (acumulator.total += currentValue.total),
            wtd: (acumulator.wtd += currentValue.store!.totalWeekToDate ?? 0),
            mtd: (acumulator.wtd += currentValue.store!.totalMonth ?? 0),
            budget: (acumulator.budget += currentValue.store!.budget ?? 0),
            used: 0,
          };
          return totals;
        },
        {
          customers: 0,
          total: 0,
          wtd: 0,
          mtd: 0,
          budget: 0,
          used: 0,
        }
      );

      if (totals.budget > 0) {
        totals.used = (totals.total / totals.budget) * 100;
      }

      setZeroTableColumns(props.availableItems, totals);
    }, [props.orders, props.availableItems, setZeroTableColumns]);

    return (
      <div className="zero-table-container">
        {ZeroTableColumnDefs.length > 0 && ZeroTableRowData.length > 0 && (
          <AgGridReact
            ref={ref}
            className={`ag-theme-alpine ag-no-header ag-no-banding ag-no-borders-pinned `}
            columnDefs={ZeroTableColumnDefs}
            rowData={ZeroTableRowData}
            headerHeight={-1}
            domLayout="autoHeight"
            alignedGrids={props.alignedGrids ? props.alignedGrids : undefined}
            suppressHorizontalScroll
            columnHoverHighlight={true}
            singleClickEdit={true}
          />
        )}
      </div>
    );
  }
);
