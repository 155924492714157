import {
  ColDef,
  RowClickedEvent,
  ValueFormatterParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import dayjs from "dayjs";
import { useCallback, useMemo, useRef } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import { useNavigate } from "react-router-dom";
import {
  CreditRequestDTO,
  CreditrequestStatus,
} from "../../../services/ApiClient";

interface IViewMerchandiseCreditRequestsTableProps {
  creditRequestData: CreditRequestDTO[];
}

export const ViewMerchandiseCreditRequestsTable = (
  props: IViewMerchandiseCreditRequestsTableProps
) => {
  const navigate = useNavigate();
  //const [error, setError] = useState<null | string>(null);
  //const [isLoaded, setIsLoaded] = useState(false);
  //const [isFetching, setIsFetching] = useState(false);
  //const [rowData, setRowData] = useState<any[]>([]);
  const gridRef = useRef<AgGridReact<any>>(null);

  const onRowSelected = useCallback(
    (selectedRow: RowClickedEvent) => {
      const creditrequestdata = selectedRow.data;

      navigate(
        `/viewMerchandiseCreditRequests/${creditrequestdata.creditrequestid}`
      );
    },
    [navigate]
  );

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
    }),
    []
  );

  const columnDefs = useMemo(
    () => [
      {
        headerName: "Date",
        field: "requestedDate",
        flex: 2,
        valueFormatter: (params: ValueFormatterParams) => {
          return dayjs(params.data.requestedDate).format("MM/DD/YYYY");
        },
        minWidth: 120,
      },
      {
        headerName: "Order ID",
        field: "orderid",
        flex: 2,
        type: "numberColumn",
        minWidth: 110,
      },
      {
        headerName: "Product Name",
        field: "productName",
        flex: 8,
        minWidth: 210,
        multiLine: true,
      },
      {
        headerName: "Quantity",
        field: "quantity",
        flex: 2,
        minWidth: 140,
      },
      {
        headerName: "Comment",
        field: "comment",
        flex: 6,
        minWidth: 310,
        multiLine: true,
      },
      {
        headerName: "Status",
        field: "status",
        flex: 2,
        valueFormatter: (params: ValueFormatterParams) => {
          return CreditrequestStatus[params.value];
        },
        minWidth: 110,
      },
    ],
    []
  );

  const columnTypes = useMemo<{
    [key: string]: ColDef;
  }>(() => {
    return {
      numberColumn: { width: 130, filter: "agNumberColumnFilter" },
      medalColumn: { width: 100, columnGroupShow: "open", filter: false },
      nonEditableColumn: { editable: false },
    };
  }, []);

  return (
    <Row className="gy-3">
      <Col md={12}>
        <Stack gap={3} direction="horizontal">
          <h5>Your Credit Requests</h5>
        </Stack>
      </Col>
      <Col md={12}>
        <Card className="shadow-sm p-0" border="light">
          <Card.Body className="p-0 available-products-card">
            <div className="ag-theme-alpine ag-row-pointer available-products-container">
              <AgGridReact
                columnDefs={columnDefs}
                columnTypes={columnTypes}
                rowData={props.creditRequestData}
                defaultColDef={defaultColDef}
                domLayout="autoHeight"
                onRowClicked={onRowSelected}
                ref={gridRef}
                rowSelection={"single"}
                pagination={true}
                paginationPageSize={10}
              />
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
